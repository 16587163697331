export default ($t) => ({
  title: "NotificationDonationVue",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    hide_projector: {
      title: $t("edit_campaign.schema.notification_donation_vue.hide_projector", "Hide projector"),
      type: "boolean",
      default: false,
      description: $t("edit_campaign.schema.notification_donation_vue.hide_projector_description", {
        returnEmptyOnMissing: true,
      }),
    },
    givingday_donations: {
      title: $t(
        "edit_campaign.schema.notification_donation_vue.givingday_donations",
        "Givingday donations"
      ),
      type: "boolean",
      default: false,
      description: $t(
        "edit_campaign.schema.notification_donation_vue.givingday_donations_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    background_color: {
      title: $t(
        "edit_campaign.schema.notification_donation_vue.background_color",
        "Background color"
      ),
      type: "color",
      default: "#000000",
      description: $t(
        "edit_campaign.schema.notification_donation_vue.background_color_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    text_color: {
      title: $t("edit_campaign.schema.notification_donation_vue.text_color", "Text color"),
      type: "color",
      default: "#ffffff",
      description: $t("edit_campaign.schema.notification_donation_vue.text_color_description", {
        returnEmptyOnMissing: true,
      }),
    },
    duration: {
      title: $t("edit_campaign.schema.notification_donation_vue.duration", "Duration"),
      type: "number",
      default: 60,
      description: $t("edit_campaign.schema.notification_donation_vue.duration_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
