export default ($t) => ({
  title: "ShowBillingAddress",
  type: "object",
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    gateway_settings: {
      title: $t("edit_campaign.schema.show_billing_address.gateway_settings", "Gateway settings"),
      type: "list-object",
      default: {},
      expanded: true,
      items: {
        type: "object",
        properties: {
          gateway: {
            title: $t(
              "edit_campaign.schema.show_billing_address.gateway_settings.items.gateway",
              "Gateway"
            ),
            type: "object",
            component: "GatewaySettingsSelect",
            required: true,
            items: {
              type: "object",
              properties: {
                address_request: {
                  title: $t(
                    "edit_campaign.schema.show_billing_address.gateway_settings.items.gateway.items.address_request",
                    "Address request"
                  ),
                  type: "boolean",
                  expanded: true,
                  default: false,
                  description: $t(
                    "edit_campaign.schema.show_billing_address.gateway_settings.items.gateway.items.address_request_description",
                    {
                      returnEmptyOnMissing: true,
                    }
                  ),
                },
                address_mandatory: {
                  title: $t(
                    "edit_campaign.schema.show_billing_address.gateway_settings.items.gateway.items.address_mandatory",
                    "Address mandatory"
                  ),
                  type: "boolean",
                  expanded: true,
                  default: false,
                  description: $t(
                    "edit_campaign.schema.show_billing_address.gateway_settings.items.gateway.items.address_mandatory_description",
                    {
                      returnEmptyOnMissing: true,
                    }
                  ),
                },
              },
            },
          },
        },
      },
    },
  },
});
