const onlyPositive = {
  lazy: true,
  handler: (value) => Math.abs(value),
};

export default ($t) => ({
  title: "MaximumDonationAmount",
  type: "object",
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    currency_to_amount: {
      title: $t(
        "edit_campaign.schema.maximum_donation_amount.currency_to_amount",
        "Currency to amount"
      ),
      type: "list-object",
      default: {},
      expanded: true,
      items: {
        type: "object",
        properties: {
          currency: {
            title: $t(
              "edit_campaign.schema.maximum_donation_amount.currency_to_amount.items.currency",
              "Currency"
            ),
            type: "object",
            component: "CurrenciesSelect",
            required: true,
          },
          amount: {
            title: $t(
              "edit_campaign.schema.maximum_donation_amount.currency_to_amount.items.amount",
              "Amount"
            ),
            type: "number",
            default: 1,
            min: 1,
            formatter: onlyPositive,
            required: true,
            description: $t(
              "edit_campaign.schema.maximum_donation_amount.currency_to_amount.items.amount_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
        },
      },
    },
  },
});
