export default ($t) => ({
  title: "PreDonationPdfForm",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    template: {
      title: $t("edit_campaign.schema.pre_donation_pdf_form.template", "Template"),
      type: "enum",
      default: "empty",
      description: $t("edit_campaign.schema.pre_donation_pdf_form.template_description", {
        returnEmptyOnMissing: true,
      }),
      items: {
        enum: [
          {
            text: "empty",
            value: "empty",
          },
          {
            text: $t("edit_campaign.schema.pre_donation_pdf_form.template.yaha", "yaha"),
            value: "yaha",
          },
          {
            text: $t("edit_campaign.schema.pre_donation_pdf_form.template.hisachdus", "hisachdus"),
            value: "hisachdus",
          },
          {
            text: $t(
              "edit_campaign.schema.pre_donation_pdf_form.template.hisachdus_v2",
              "hisachdus-v2"
            ),
            value: "hisachdus-v2",
          },
          {
            text: $t("edit_campaign.schema.pre_donation_pdf_form.template.sitedeed", "sitedeed"),
            value: "sitedeed",
          },
          {
            text: $t(
              "edit_campaign.schema.pre_donation_pdf_form.template.elul_tashpad",
              "elul-tashpad"
            ),
            value: "elul-tashpad",
          },
          {
            text: $t(
              "edit_campaign.schema.pre_donation_pdf_form.template.mishkan_menachem",
              "mishkan-menachem"
            ),
            value: "mishkan-menachem",
          },
        ],
      },
    },
    exclude_trgets: {
      title: $t("edit_campaign.schema.pre_donation_pdf_form.exclude_trgets", "Exclude targets"),
      type: "array",
      default: ["main", "team", "level"],
      description: $t("edit_campaign.schema.pre_donation_pdf_form.exclude_trgets_description", {
        returnEmptyOnMissing: true,
      }),
      items: {
        title: "Items",
        type: "string",
        default: "",
      },
    },
    pre_set_amount: {
      title: $t("edit_campaign.schema.pre_donation_pdf_form.pre_set_amount", "Pre set amount"),
      type: "number",
      default: 0,
      description: $t("edit_campaign.schema.pre_donation_pdf_form.pre_set_amount_description", {
        returnEmptyOnMissing: true,
      }),
    },
    pre_set_currency: {
      title: $t("edit_campaign.schema.pre_donation_pdf_form.pre_set_currency", "Pre set currency"),
      type: "string",
      default: "",
      maxLength: 256,
      description: $t("edit_campaign.schema.pre_donation_pdf_form.pre_set_currency_description", {
        returnEmptyOnMissing: true,
      }),
    },
    txt_under_submit_btn: {
      title: $t(
        "edit_campaign.schema.pre_donation_pdf_form.txt_under_submit_btn",
        "Text under submit btn"
      ),
      type: "boolean",
      default: false,
      expanded: true,
      description: $t(
        "edit_campaign.schema.pre_donation_pdf_form.txt_under_submit_btn_description",
        "only 'hisachdus-v2', 'sitedeed', 'elul-tashpad' templates"
      ),
    },
    currency_min_amount: {
      type: "object",
      properties: {
        usd: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.currency_min_amount.usd",
            "Currency min amount"
          ),
          type: "number",
          default: 0,
          description: $t(
            "edit_campaign.schema.pre_donation_pdf_form.currency_min_amount.usd_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
      },
    },
    currency_min_amount_warning: {
      type: "object",
      properties: {
        usd: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.currency_min_amount_warning.usd",
            "Currency min amount warning"
          ),
          type: "number",
          default: 0,
          description: $t(
            "edit_campaign.schema.pre_donation_pdf_form.currency_min_amount_warning.usd_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
      },
    },
    layout_data: {
      title: $t(
        "edit_campaign.schema.pre_donation_pdf_form.layout_data",
        "Layout for an empty template"
      ),
      type: "object",
      expanded: true,
      properties: {
        img: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.layout_data.img",
            "Bg image for an empty template"
          ),
          type: "string",
          default:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1733257614_6364626464346130396136343930393639343238316165633530333734366664_31373333323537363134.jpg",
          maxLength: 512,
          description: $t(
            "edit_campaign.schema.pre_donation_pdf_form.layout_data.img_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
        form_currency: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency",
            "Form currency"
          ),
          type: "list",
          default: [
            {
              size: 320,
              css_attr: "top",
              css_value: "46.2%",
            },
            {
              size: 569,
              css_attr: "top",
              css_value: "45.6%",
            },
            {
              size: 992,
              css_attr: "top",
              css_value: "46.2%",
            },
          ],
          items: {
            type: "object",
            properties: {
              size: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency.size",
                  "Size"
                ),
                type: "number",
                default: 320,
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency.size_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_attr: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency.css_attr",
                  "CSS attr"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency.css_attr_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_value: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency.css_value",
                  "CSS value"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_currency.css_value_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
        form_amount: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount",
            "Form amount"
          ),
          type: "list",
          default: [
            {
              size: 320,
              css_attr: "top",
              css_value: "45%",
            },
            {
              size: 569,
              css_attr: "top",
              css_value: "43%",
            },
            {
              size: 992,
              css_attr: "top",
              css_value: "45%",
            },
          ],
          items: {
            type: "object",
            properties: {
              size: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount.size",
                  "Size"
                ),
                type: "number",
                default: 320,
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount.size_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_attr: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount.css_attr",
                  "CSS attr"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount.css_attr_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_value: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount.css_value",
                  "CSS value"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_amount.css_value_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
        form_name: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name",
            "Form name"
          ),
          type: "list",
          default: [
            {
              size: 320,
              css_attr: "top",
              css_value: "30%",
            },
            {
              size: 569,
              css_attr: "top",
              css_value: "28%",
            },
            {
              size: 992,
              css_attr: "top",
              css_value: "30%",
            },
          ],
          items: {
            type: "object",
            properties: {
              size: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name.size",
                  "Size"
                ),
                type: "number",
                default: 320,
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name.size_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_attr: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name.css_attr",
                  "CSS attr"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name.css_attr_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_value: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name.css_value",
                  "CSS value"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_name.css_value_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
        form_submit: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit",
            "Form submit"
          ),
          type: "list",
          default: [
            {
              size: 320,
              css_attr: "top",
              css_value: "86%",
            },
            {
              size: 1360,
              css_attr: "top",
              css_value: "90%",
            },
          ],
          items: {
            type: "object",
            properties: {
              size: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit.size",
                  "Size"
                ),
                type: "number",
                default: 320,
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit.size_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_attr: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit.css_attr",
                  "CSS attr"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit.css_attr_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              css_value: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit.css_value",
                  "CSS value"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.layout_data.form_submit.css_value_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
      },
    },
    custom_radio_btn_row: {
      title: $t(
        "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row",
        "Buttons for 'hisachdus' and 'hisachdus-v2' templates"
      ),
      type: "object",
      expanded: true,
      properties: {
        custom_field_key: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.custom_field_key",
            "Custom field key"
          ),
          type: "string",
          default: "test",
          description: $t(
            "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.custom_field_key_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
        buttons: {
          title: $t(
            "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.buttons",
            "Buttons"
          ),
          type: "list",
          default: [
            { caption: "Toggle 1", value: "1" },
            { caption: "Toggle 2", value: "2" },
            { caption: "Toggle 3", value: "3" },
          ],
          items: {
            type: "object",
            properties: {
              caption: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.buttons.caption",
                  "Caption"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.buttons.caption_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              value: {
                title: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.buttons.value",
                  "Value"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.pre_donation_pdf_form.custom_radio_btn_row.buttons.value_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
      },
    },
  },
});
