export default ($t) => ({
  title: "NotificationsFromEmail",
  type: "object",
  expanded: true,
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: true,
      expanded: true,
    },
    email: {
      title: $t("edit_campaign.schema.notifications_from_email.email", "Email"),
      type: "string",
      default: "",
      maxLength: 256,
      expanded: true,
      description: $t("edit_campaign.schema.notifications_from_email.email_description", {
        returnEmptyOnMissing: true,
      }),
    },
    name: {
      title: $t("edit_campaign.schema.notifications_from_email.name", "Name"),
      type: "string",
      default: "",
      maxLength: 256,
      expanded: true,
      description: $t("edit_campaign.schema.notifications_from_email.name_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
