<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  AGENT_NAME: "charidy-assistant---campaign-manager",
  BASE_URL: (agentName, token, orgid, orgname, username, language) =>
    `https://SmartUp.lat/agente/${agentName}?mode=iframe&token=${token}&orgid=${orgid}&orgname=${orgname}&username=${username}&language=${language}`,

  data() {
    return {
      visibility: false,
    };
  },
  computed: {
    ...mapState({
      enableCharidyAssistant: (state) => state.account.enableCharidyAssistant,
      token: () => localStorage.getItem("jwt_token"),
      orgId: (state) => state.account.account.data.id,
      orgName: (state) => state.account.account.data.attributes.name,
      fullName: (state) => state.account.account.data.attributes.full_name,
      lang: (state) => state.translation.locate,
    }),
    src() {
      return encodeURI(
        this.$options.BASE_URL(
          this.$options.AGENT_NAME,
          this.token,
          this.orgId,
          this.orgName,
          this.fullName,
          this.lang
        )
      );
    },
    style() {
      return {
        visibility: this.visibility ? "visible" : "hidden",
      };
    },
  },
  methods: {
    toggleVisibility() {
      this.visibility = !this.visibility;
    },
  },
});
</script>

<template>
  <div v-if="enableCharidyAssistant" id="SmartUp-container">
    <button id="toggle-button" class="chat-button" @click="toggleVisibility">
      <b-icon-chat-dots-fill shift-v="1" v-if="!visibility" />
      <b-icon-x-lg v-else />
    </button>
    <iframe v-if="visibility" :style="style" id="chat-iframe" :src="src"></iframe>
  </div>
</template>

<style lang="scss" scoped>
/* Style for SmartUp chat container */
#SmartUp-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000001;
}

/* Style for chat activation button */
.chat-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1px;
  bottom: 25px;
  right: 5px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10000002;

  background-color: $bb-100; /* Editable: Background color */
  width: 60px; /* Editable: Size */
  height: 60px; /* Editable: Size */
  font-size: 32px; /* Editable: Icon/emoji size */
  color: #fff; /* Editable: Icon/emoji color */
}

/* Style for chat iframe */
#chat-iframe {
  position: absolute;
  bottom: 70px;
  right: 10px;
  border: none;
  border-radius: 20px;
  visibility: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9);
  width: 50vw; /* Editable: Width */
  height: 90vh; /* Editable: Height */
  background: $bb-100; /* Editable: Background color with transparency */
}

/* Responsive styles for mobile and larger devices */
@media (min-width: 1500px) {
  #chat-iframe {
    width: 35vw;
  }
}
@media only screen and (max-width: 768px) {
  #chat-iframe {
    width: 95vw;
    height: 85vh;
  }
}
@media only screen and (max-width: 768px) {
  #chat-iframe {
    width: 95vw;
    height: 80vh;
  }
}
</style>
