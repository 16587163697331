<template>
  <b-card-body class="align-content-end">
    <portal :to="tableListGroupSlotId">
      <b-list-group-item class="p-0" v-if="value.length > 0">
        <b-table :items="value" :fields="fields()">
          <template #cell(title)="data">
            <div :style="{ 'max-width': '300px' }">
              {{ data.value }}
            </div>
          </template>
          <template #cell(image_src)="data">
            <b-img :src="data.value" height="100" />
          </template>
          <template #cell(src)="data">
            <video
              v-if="data.item.type === 'video'"
              controls="controls"
              :src="data.value"
              type="video/mp4"
              height="100"
            />
            <b-img v-else :src="data.value" height="100" />
          </template>
          <template #cell(actions)="{ index }">
            <b-row>
              <b-col v-if="value.length > 1">
                <b-icon-caret-up-fill
                  v-if="index !== 0"
                  variant="danger"
                  role="button"
                  font-scale="1.2"
                  @click="move('up', index)"
                />
                <b-icon-caret-down-fill
                  v-if="index !== value.length - 1"
                  variant="danger"
                  role="button"
                  font-scale="1.2"
                  @click="move('down', index)"
                />
              </b-col>
              <b-col style="align-self: center">
                <b-icon-trash-fill
                  variant="danger"
                  role="button"
                  font-scale="1.2"
                  @click="remove(index)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-list-group-item>
    </portal>
    <slot name="form" :value="item" :submit="submit"></slot>
    <portal :to="cardFooterSlotId">
      <b-button variant="secondary" block :form="form" type="submit" :disabled="loading">
        <b-spinner v-if="loading" small></b-spinner>
        Add
      </b-button>
    </portal>
    <MediaStorageCropperModal :campaignTemplate="schemeName" ref="MediaStorageCropperModal" />
  </b-card-body>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import { isPlainObject } from "@/helpers/inspect";
import { notificationsMixin } from "@/mixins";
import { OpenMediaStoragePreview, OnOkMediaStoragePreview } from "@/constants/providers";
import MediaStorageCropperModal from "@/views/media-storage/MediaStorageCropperModal.vue";

export default {
  inject: {
    OpenMediaStoragePreview,
    OnOkMediaStoragePreview,
  },
  mixins: [notificationsMixin],
  components: {
    MediaStorageCropperModal,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    scheme: {
      type: Object,
      default: () => null,
    },
    form: {
      type: String,
      default: "",
    },
    schemeName: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "column",
    },
    cardFooterSlotId: {
      type: String,
      default: "",
    },
    tableListGroupSlotId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      item: {},
      loading: false,
    };
  },

  computed: {},

  methods: {
    isUrlValid(userInput) {
      var res = userInput.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g
      );
      if (res == null) return false;
      else return true;
    },

    checkIsImage(url) {
      const image = document.createElement("img");

      return new Promise((resolve) => {
        image.addEventListener("load", () => {
          if (image.width > 0) {
            resolve(true);
          }
        });
        image.addEventListener("error", () => {
          resolve(false);
        });
        if (this.isUrlValid(url)) {
          image.src = url;
        } else {
          resolve(false);
        }
      });
    },

    checkIsVideo(url) {
      const video = document.createElement("video");

      return new Promise((resolve) => {
        video.addEventListener("loadeddata", () => {
          resolve(true);
        });
        video.addEventListener("canplaythrough", () => {
          resolve(true);
        });
        video.addEventListener("error", () => {
          resolve(false);
        });
        video.addEventListener("emptied", () => {
          resolve(false);
        });
        video.addEventListener("ended", () => {
          resolve(false);
        });
        video.addEventListener("waiting", () => {
          resolve(false);
        });
        if (this.isUrlValid(url)) {
          video.setAttribute("src", url);
        } else {
          resolve(false);
        }
      });
    },

    getMediaUrl(file) {
      return new Promise((resolve) => {
        if (file instanceof File || file instanceof Blob) {
          const formData = new FormData();

          formData.append("file", file);

          axios.post(urls.loadImageQuill, formData).then((response) => {
            resolve(response.data.src);
          });
        } else {
          resolve(file);
        }
      });
    },

    convertToCssStyle(to, from) {
      return Object.entries(to)
        .reduce((acc, curr) => {
          const [cssKey, value] = curr;
          acc.push(
            `${cssKey}:${
              !from[cssKey] || from[cssKey] === "" || from[cssKey] === 0
                ? "inherit"
                : `${from[cssKey]}${value.cssValue}`
            }`
          );
          return acc;
        }, [])
        .join(";");
    },

    async add() {
      const item = this.item;

      if (
        item.image_src &&
        Array.isArray(item.image_src) &&
        this.scheme.properties?.image_src?.multiple
      ) {
        let arr = [];
        let res;

        await new Promise((resolve) => {
          let process = false;
          this.$refs.MediaStorageCropperModal.open({
            file: item.image_src[0],
            files: item.image_src,
            tag: this.schemeName,
            allowAnyAspectRatio: this.scheme.properties?.image_src?.allowAnyAspectRatio,
          });
          this.$refs.MediaStorageCropperModal.onOK(async (blob) => {
            process = true;
            res = null;
            if (blob && blob.length)
              for (let i = 0; i < blob.length; i++) {
                res = await this.getMediaUrl(blob[i]);
                arr.push({ image_src: res, title: item.title, url: item.url });
              }
            resolve();
            process = false;
          });
          this.$refs.MediaStorageCropperModal.onClose(() => {
            if (!process) {
              resolve();
            }
          });
        });
        this.onInput(arr, true);
        return;
      }

      if (Object.keys(item).length) {
        if (this.scheme?.validation === "unidy_media_stripe") {
          for (const [k, v] of Object.entries(item)) {
            if (this.scheme.properties[k].inputType === "text/file" && !v) {
              return Promise.reject(new Error("Files are required"));
            }

            if (isPlainObject(v) && this.scheme.properties[k].type === "style") {
              item[k] = this.convertToCssStyle(this.scheme.properties[k].properties, v);
            } else if (
              item.type === "auto" &&
              this.scheme.properties[k].inputType === "text/file" &&
              v
            ) {
              const url = await this.getMediaUrl(v);

              const isVideo = await this.checkIsVideo(url);
              if (isVideo) {
                item.type = "video";
                item.show_as = "video_tag";
                item[k] = url;
                continue;
              }

              const isImage = await this.checkIsImage(url);
              if (isImage) {
                item.type = "image";
                item[k] = url;
                continue;
              }

              return Promise.reject(new Error("Provided file is not image or video!"));
            } else if (v) {
              const res = await this.getMediaUrl(v);
              item[k] = res;
            }
          }
        } else {
          for (const [k, v] of Object.entries(item)) {
            if (k.startsWith("$")) {
              for (const [k$1, v$1] of Object.entries(v)) {
                item[k$1] = v$1;
              }
              delete item[k];
              continue;
            }

            let res = v;
            if (!this.scheme.properties[k].preview) {
              res = await this.getMediaUrl(v);
            }
            item[k] = res;
          }
        }

        let itemHasPreviewSet = null;
        let itemHasAllowAnyAspectRatioSet = null;
        for (const k of Object.keys(item)) {
          const prop = this.scheme.properties[k];
          if (prop?.preview) {
            itemHasPreviewSet = k;
            itemHasAllowAnyAspectRatioSet = prop.allowAnyAspectRatio;
          }
        }

        if (itemHasPreviewSet) {
          return new Promise((resolve) => {
            this.$refs.MediaStorageCropperModal.open({
              file: this.item[itemHasPreviewSet],
              tag: this.schemeName,
              allowAnyAspectRatio: itemHasAllowAnyAspectRatioSet,
            });
            this.$refs.MediaStorageCropperModal.onOK(async (blob) => {
              this.item[itemHasPreviewSet] = await this.getMediaUrl(blob);
              this.onInput(this.item);
              resolve();
            });
            this.$refs.MediaStorageCropperModal.onClose(() => {
              resolve();
            });
          });
        } else {
          this.onInput(this.item);
        }
      }
    },

    onInput(item, isItemArr = null) {
      if (isItemArr) {
        // eslint-disable-next-line vue/no-mutating-props
        this.value.push(...item);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.value.push(item);
      }

      this.item = {};
      this.loading = false;

      this.$emit("input", this.value);
    },

    submit() {
      this.loading = true;
      this.add()
        .catch((e) => {
          this.$_notificationsMixin_makeErrorToast("Error", e?.message || "Something went wrong");
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    remove(i) {
      const newList = [...this.value];

      newList.splice(i, 1);

      this.$emit("input", newList);
    },

    move(direction, i) {
      if (direction === "up") {
        this.swapElements(i, i - 1);
      } else if (direction === "down") {
        this.swapElements(i, i + 1);
      }
      return;
    },

    swapElements(indexA, indexB) {
      const newList = [...this.value];

      var temp = newList[indexA];
      newList[indexA] = newList[indexB];
      newList[indexB] = temp;

      this.$emit("input", newList);
    },

    fields() {
      const keys = Object.keys(this.value[0] || {});
      return [
        ...keys,
        ...((keys.length === 0 && ["placeholder"]) || []),
        { key: "actions", tdClass: "align-middle text-center" },
      ];
    },
  },
};
</script>
