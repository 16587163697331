export default ($t) => ({
  title: "DonorRescueNotification",
  type: "object",
  expanded: true,
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: true,
    },
    do_not_sent_to_team_leader: {
      title: $t(
        "edit_campaign.schema.donor_rescue_notification.do_not_sent_to_team_leader",
        "Do not sent to team leader"
      ),
      type: "boolean",
      default: true,
      description: $t(
        "edit_campaign.schema.donor_rescue_notification.do_not_sent_to_team_leader_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    do_not_sent_to_donor: {
      title: $t(
        "edit_campaign.schema.donor_rescue_notification.do_not_sent_to_donor",
        "Do not sent to donor"
      ),
      type: "boolean",
      default: true,
      description: $t(
        "edit_campaign.schema.donor_rescue_notification.do_not_sent_to_donor_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    do_not_sent_to_org: {
      title: $t(
        "edit_campaign.schema.donor_rescue_notification.do_not_sent_to_org",
        "Do not sent to org"
      ),
      type: "boolean",
      default: true,
      description: $t(
        "edit_campaign.schema.donor_rescue_notification.do_not_sent_to_org_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
  },
});
