import urls from "@/constants/urls";
import axios from "axios";

/**
 * @typedef {Object} PaymentSystemListResponse
 * @property {PaymentSystemItem[]} data - An array of payment system items.
 */

/**
 * @typedef {Object} PaymentSystemItem
 * @property {number} id - The unique identifier for the payment system item.
 * @property {string} system_name - The name of the payment system.
 * @property {string} label - The label for the payment system item.
 * @property {string} description - A description of the payment system item.
 * @property {string} logo - The URL or path to the logo of the payment system.
 * @property {boolean} recurring - Indicates whether the payment system supports recurring payments.
 */

export const usePaymentSystemListApi = (oid) => {
  /**
   * @param params {Object} - query params
   * @returns {Promise<PaymentSystemListResponse>}
   */
  async function getPaymentSystemList(params = {}) {
    const url = urls.paymentSystemList(oid);
    return axios.get(url, { params }).then((r) => r.data);
  }

  return { getPaymentSystemList };
};
