const onlyPositive = {
  lazy: true,
  handler: (value) => {
    const number = Number(value);
    if (Number.isNaN(number)) {
      return value;
    }
    return Math.abs(value);
  },
};

const onlyPositivePercent = {
  lazy: true,
  handler: (value) => {
    if (value > 100) {
      return 100;
    }
    const number = Number(value);
    if (Number.isNaN(number)) {
      return value;
    }
    return Math.abs(value);
  },
};

export default ($t) => ({
  title: "DonationUpsell",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    upsell_thresholds: {
      title: $t("edit_campaign.schema.donation_upsell.upsell_thresholds", "Upsell Thresholds"),
      type: "list",
      default: [],
      expanded: true,
      items: {
        type: "object",
        properties: {
          amount_from: {
            title: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.amount_from",
              "Amount From"
            ),
            type: "number",
            default: 1,
            min: 1,
            formatter: onlyPositive,
            description: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.amount_from_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          amount_to: {
            title: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.amount_to",
              "Amount To"
            ),
            type: "number",
            default: 1,
            min: 1,
            formatter: onlyPositive,
            description: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.amount_to_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          upsell_type: {
            title: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_type",
              "Upsell Type"
            ),
            type: "enum",
            default: "onetime",
            description: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_type_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
            items: {
              enum: [
                {
                  text: $t(
                    "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_type.items.enum.onetime",
                    "onetime"
                  ),
                  value: "onetime",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_type.items.enum.recurring",
                    "recurring"
                  ),
                  value: "recurring",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_type.items.enum.installments",
                    "installments"
                  ),
                  value: "installments",
                },
              ],
            },
          },
          upsell_duration: {
            title: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_duration",
              "Upsell Duration"
            ),
            type: "number",
            default: 0,
            formatter: onlyPositive,
            description: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_duration_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          upsell_message: {
            title: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_message",
              "Upsell Message"
            ),
            type: "string",
            default: "",
            description: $t(
              "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_message_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          $upsell_amount_enum: {
            type: "object",
            component: "UpsellAmountEnum",
            expanded: true,
            properties: {
              upsell_amount: {
                title: $t(
                  "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_amount_enum.upsell_amount",
                  "Upsell Amount"
                ),
                type: "number",
                default: 1,
                min: 1,
                formatter: onlyPositive,
              },
              upsell_amount_percentage: {
                title: $t(
                  "edit_campaign.schema.donation_upsell.upsell_thresholds.items.upsell_amount_enum.upsell_amount_percentage",
                  "Upsell Amount Percentage"
                ),
                type: "number",
                default: 0,
                min: 0,
                max: 100,
                formatter: onlyPositivePercent,
              },
            },
          },
        },
      },
    },
  },
});
