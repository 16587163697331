export default ($t) => ({
  title: "UnidyMediaStripe",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    hide_on_team_page: {
      title: $t("edit_campaign.schema.unidy_media_stripe.hide_on_team_page", "Hide on team page"),
      type: "boolean",
      default: false,
      description: $t("edit_campaign.schema.unidy_media_stripe.hide_on_team_page_description", {
        returnEmptyOnMissing: true,
      }),
    },
    medias: {
      title: $t("edit_campaign.schema.unidy_media_stripe.medias", "Medias"),
      type: "list",
      default: [],
      expanded: true,
      layout: "expanded",
      items: {
        type: "object",
        validation: "unidy_media_stripe",
        properties: {
          type: {
            title: $t("edit_campaign.schema.unidy_media_stripe.medias.type", "Type"),
            type: "enum",
            default: "auto",
            description: $t("edit_campaign.schema.unidy_media_stripe.medias.type_description", {
              returnEmptyOnMissing: true,
            }),
            items: {
              enum: [
                {
                  text: $t(
                    "edit_campaign.schema.unidy_media_stripe.medias.type.items.enum.auto",
                    "Auto"
                  ),
                  value: "auto",
                },
                {
                  text: $t(
                    "edit_campaign.schema.unidy_media_stripe.medias.type.items.enum.image",
                    "Image"
                  ),
                  value: "image",
                },
                {
                  text: $t(
                    "edit_campaign.schema.unidy_media_stripe.medias.type.items.enum.video",
                    "Video"
                  ),
                  value: "video",
                },
              ],
            },
          },
          src: {
            title: $t("edit_campaign.schema.unidy_media_stripe.medias.src", "Image/Video File"),
            type: "image/video",
            inputType: "text/file",
            required: true,
            description: $t("edit_campaign.schema.unidy_media_stripe.medias.src_description", {
              returnEmptyOnMissing: true,
            }),
          },
          style: {
            title: $t("edit_campaign.schema.unidy_media_stripe.medias.style", "Video Style"),
            type: "style",
            properties: {
              width: {
                title: $t("edit_campaign.schema.unidy_media_stripe.medias.style.width", "Width"),
                type: "number",
                default: 480,
                cssValue: "px",
                description: $t(
                  "edit_campaign.schema.unidy_media_stripe.medias.style.width_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              height: {
                title: $t("edit_campaign.schema.unidy_media_stripe.medias.style.height", "Height"),
                type: "number",
                default: 270,
                cssValue: "px",
                description: $t(
                  "edit_campaign.schema.unidy_media_stripe.medias.style.height_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
      },
    },
  },
});
