export default ($t) => ({
  title: "AddTeamFromCampaign",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
      expanded: true,
    },
    team_id_exception: {
      expanded: true,
      title: $t("edit_campaign.schema.add_team_from_campaign.team_id_exception", "Exception"),
      type: "list",
      component: "TeamMultiselectSearch",
      componentAttrs: {
        taggable: true,
        multiple: true,
      },
    },
  },
});
