<template>
  <div class="w-100 mb-4">
    <div class="d-flex justify-content-between mb-3">
      <span class="font-weight-bold">
        {{ field.title }}
      </span>
    </div>

    <form-generetor v-model="valueModel" :scheme="scheme" />

    <div class="d-flex justify-content-end my-3">
      <b-btn class="bttn bttn--sm bttn--orange" @click="addNew">
        {{ $t("edit_campaign.setting_goal_categories_add", "Add new") }}
      </b-btn>
    </div>

    <goal-categories-modal ref="addEditModal" @save="save" />

    <b-table small stacked="md" :items="items" :fields="fields">
      <template #cell(image)="data">
        <b-img :src="data.value" height="100" />
      </template>
      <template #cell(title)="data">
        <div :style="{ 'max-width': '200px' }">
          {{ data.value }}
        </div>
      </template>
      <template #cell(subtitle)="data">
        <div :style="{ 'max-width': '200px' }">
          {{ data.value }}
        </div>
      </template>
      <template #cell(actions)="{ index, item }">
        <b-icon-pencil
          class="mx-1"
          variant="warning"
          role="button"
          font-scale="1.2"
          @click="edit(index, item)"
        />

        <b-icon-trash-fill
          class="mx-1"
          variant="danger"
          role="button"
          font-scale="1.2"
          @click="remove(index)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import formGeneretor from "@/common-components/form-generetor/form-generetor";
import goalCategoriesModal from "./goal-categories-modal.vue";

export default {
  components: { formGeneretor, goalCategoriesModal },

  props: {
    value: {
      type: Object,
      default: () => null,
    },
    field: {
      type: Object,
      default: () => null,
    },
    fieldKey: {
      type: String,
      default: "",
    },
  },

  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(data) {
        this.$set(this, "value", { ...this.value, ...data });
      },
    },

    scheme() {
      return {
        type: "object",
        properties: {
          value: {
            title: "Active",
            type: "boolean",
            internal: true,
          },
          view: {
            title: "View Mode",
            type: "enum",
            items: {
              enum: [
                {
                  text: "Card list",
                  value: "card-list",
                },
                {
                  text: "Dropdown list",
                  value: "standard",
                },
              ],
            },
          },
        },
      };
    },

    items() {
      return this.value.options || [];
    },

    fields() {
      return [
        { key: "key", tdClass: "align-middle" },
        { key: "image", tdClass: "align-middle" },
        { key: "title", tdClass: "align-middle" },
        { key: "subtitle", tdClass: "align-middle" },
        { key: "price", tdClass: "align-middle" },
        { key: "level_ids", tdClass: "align-middle" },
        { key: "raffle_hot_button_keys", tdClass: "align-middle" },
        { key: "actions", tdClass: "align-middle text-center" },
      ];
    },
  },

  methods: {
    updateValue(key, value) {
      this.$set(this.value, key, value);
      this.$emit("input", this.value);
    },

    addNew() {
      this.$refs.addEditModal.open();
    },

    edit(idx, item) {
      this.$refs.addEditModal.open(true, idx, item);
    },

    save({ index, item }) {
      const temp = [...this.items];

      if (index >= 0) {
        temp[index] = item;
      } else {
        temp.push(item);
      }

      this.updateValue("options", temp);
    },

    remove(i) {
      const temp = [...this.items];

      temp.splice(i, 1);

      this.updateValue("options", temp);
    },
  },
};
</script>

<style lang="scss" scoped></style>
