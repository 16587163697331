<template>
  <div>
    <b-modal
      id="media-storage-cropper-modal"
      ref="modal"
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="onHide"
      @ok="ok"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ $t(`dashboard.media_storage_cropper_modal_title`, `Crop media`) }}
        </h2>
      </div>
      <div>
        <div class="tw-flex tw-flex-wrap tw-pb-4 tw-gap-4">
          <img
            v-for="(file, index) in files"
            :key="index"
            :src="getImageSrc(file)"
            :alt="index"
            class="tw-w-[126px] tw-h-[70px] tw-border-4 tw-border-solid tw-object-cover tw-border-transparent tw-cursor-pointer hover:tw-border-[#107598] tw-transition-all"
            :style="current === index ? 'border-color: #107598;' : ''"
            @click="changeFile(file, index)"
          />
        </div>
        <b-alert class="!tw-pt-0" :show="srcImage && aspectRatio && aspectRatio !== 1">
          <div class="tw-prose tw-prose-sm" v-html="aspectRatioInfo"></div>
        </b-alert>
        <b-row v-if="allowAnyAspectRatio" class="mb-2">
          <b-col>
            <b-form-checkbox
              v-model="anyAspectRatioToggle"
              id="anyAspectRatioToggle"
              name="anyAspectRatioToggle"
            >
              <label class="form-unit__label" for="anyAspectRatioToggle">
                {{
                  $t(
                    `dashboard.media_storage_cropper_modal_any_aspect_ratio`,
                    `Allow any aspect ratio`
                  )
                }}
              </label>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="srcImage && !this.tag.includes('video')">
          <b-col cols="6">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("dashboard.media_storage_original_img", "Original image:") }}
            </legend>
            <MediaStorageCropper
              ref="cropper"
              :src="srcImage"
              :stencil-props="{ aspectRatio }"
              :tag="tag"
              @change="onChange"
            />
          </b-col>
          <b-col v-show="previewImageSrc" cols="6">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{ $t("dashboard.media_storage_final_img", "Final image:") }}
            </legend>
            <MediaStorageCropperPreview :image="previewImageSrc" :tag="tag" />
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label">
              {{
                $t("dashboard.media_storage_final_img_size", "Image size: {x}x{y}", {
                  x: cropperCoordinates.width,
                  y: cropperCoordinates.height,
                })
              }}
            </legend>
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              {{
                $t(
                  "dashboard.media_storage_final_img_aspect_ratio",
                  "Aspect ratio: {aspectRatioCropperCoordinates}",
                  {
                    aspectRatioCropperCoordinates,
                  }
                )
              }}
            </legend>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="bttn bttn--lg bttn--blue" @click="cancel">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>
        <button v-if="files.length" class="bttn bttn--lg bttn--orange" @click="apply">Apply</button>
        <button class="bttn bttn--lg bttn--orange" @click="ok">Ok</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  filterMediaTagsByTemplate,
  flattenMediaTags,
  getImageDimensions,
  mediaTagAspectRatio,
} from "@/constants/media-tags";
import { mapActions, mapState } from "vuex";
import MediaStorageCropper from "./MediaStorageCropper.vue";
import MediaStorageCropperPreview from "./MediaStorageCropperPreview.vue";

function gcd(a, b) {
  return b == 0 ? a : gcd(b, a % b);
}

export default {
  props: {
    campaignTemplate: {
      type: String,
      default: "",
    },
  },
  components: {
    MediaStorageCropper,
    MediaStorageCropperPreview,
  },
  data() {
    return {
      src: "",
      tag: "",
      file: null,
      files: [],
      filesUrls: null,
      current: 0,
      customAspectRatio: null,
      previewImageSrc: "",
      cropperCanvas: null,
      cropperCoordinates: { width: 0, height: 0 },
      callback: () => null,
      callbackClose: () => null,
      allowAnyAspectRatio: false,
      anyAspectRatioToggle: false,
    };
  },
  created() {
    this.$options.filesUrls = [];
  },
  computed: {
    ...mapState({
      metas: (state) => state.campaign.metas,
    }),

    optionsTags() {
      return filterMediaTagsByTemplate(this.campaignTemplate);
    },
    optionsAspectRatio() {
      return mediaTagAspectRatio({});
    },
    flatOptionsTags() {
      return flattenMediaTags();
    },

    fileUrl() {
      if (this.files.length && this.file) {
        const someUrl = URL.createObjectURL(this.file);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$options.filesUrls.push(someUrl);
        return someUrl;
      } else {
        return this.file && URL.createObjectURL(this.file);
      }
    },
    srcImage() {
      return this.fileUrl || this.src;
    },
    aspectRatio() {
      if (this.anyAspectRatioToggle) {
        return null;
      }

      if (this.customAspectRatio) {
        return this.customAspectRatio?.value || 1;
      }

      const mtar = this.optionsAspectRatio[this.tag];
      if (mtar && (mtar.value?.height || mtar.value?.width)) {
        return mtar.value.height / mtar.value.width;
      }

      return (mtar && mtar.value) || 1;
    },

    aspectRatioInfo() {
      const tag = this.flatOptionsTags.find((el) => el.value === this.tag)?.text || "";
      const mtar = this.optionsAspectRatio[this.tag];
      const ratio = mtar?.value || 1;
      const { width, height } = getImageDimensions(ratio);
      const imageInfo = `<p>The recommended aspect ratio is <b>{aspectRatio}</b> ({width}x{height}px)</p>
<p>Upload an image and the cropping tool will appear below so you can adjust the original image according to these dimensions.</p>
<p>The recommended aspect ratio is based on the Full HD reference size (1920x1080px).</p>`;
      const fallback = `<dl>
<dt>Template:</dt>
<dd>{tmpl}</dd>
<dt>Tag:</dt>
<dd>{tag}</dd>
</dl>
{imageInfo}`;

      const tvars = {
        tmpl: this.campaignTemplate.replace(/\b[a-z]/g, (x) => x.toUpperCase()),
        tag,
        aspectRatio: this.aspectRatioText,
        width,
        height,
      };
      return this.$t("dashboard.media_storage_aspect_ratio_info", fallback, {
        imageInfo: !this.tag.includes("video")
          ? this.$t("dashboard.media_storage_aspect_ratio_image_info", imageInfo, tvars)
          : "",
        ...tvars,
      });
    },

    aspectRatioText() {
      if (this.customAspectRatio) {
        return this.customAspectRatio?.text || "";
      }

      const mtar = this.optionsAspectRatio[this.tag];
      return (mtar && mtar.text) || "";
    },

    aspectRatioCropperCoordinates() {
      if (this.cropperCoordinates) {
        const w = this.cropperCoordinates.width;
        const h = this.cropperCoordinates.height;
        const r = gcd(w, h);
        return `${w / r}:${h / r}`;
      }

      return "";
    },
  },
  methods: {
    ...mapActions({
      fetchCreateFileUrl: "mediaStorage/fetchCreateFileUrl",
    }),
    open({ file, tag, aspectRatio, allowAnyAspectRatio, files } = {}) {
      this.file = file;
      this.files = files || [];
      this.tag = tag;
      this.customAspectRatio = aspectRatio;
      this.allowAnyAspectRatio = allowAnyAspectRatio;
      this.$refs.modal.show();
    },
    getImageSrc(file) {
      const someUrl = URL.createObjectURL(file);
      this.$options.filesUrls.push(someUrl);
      return someUrl;
    },
    changeFile(file, index) {
      this.file = file;
      this.current = index;
    },
    close() {
      this.$refs.modal.hide();
    },
    onChange({ canvas, coordinates }) {
      this.previewImageSrc = canvas?.toDataURL() || "";
      this.cropperCanvas = canvas;
      this.cropperCoordinates = coordinates;
    },
    getCroppedImgURL() {
      return new Promise((resolve) => {
        if (this.cropperCanvas) {
          this.cropperCanvas.toBlob((blob) => {
            const file = new File([blob], `${Date.now()}.jpeg`, {
              lastModified: Date.now(),
              type: "image/jpeg",
            });
            resolve(file);
          }, "image/jpeg");
        } else {
          resolve(this.src);
        }
      });
    },
    onOK(cb) {
      this.callback = cb;
    },
    onClose(cb) {
      this.callbackClose = cb;
    },
    ok() {
      if (this.files.length) {
        this.callback(this.files);
      } else {
        this.getCroppedImgURL().then(this.callback);
      }
    },
    async apply() {
      const croppedFile = await this.getCroppedImgURL();
      this.files[this.current] = croppedFile;
      this.file = this.files[this.current];
    },
    onHide() {
      this.$emit("hide");
      this.callbackClose();
      this.fileUrl && URL.revokeObjectURL(this.fileUrl);
      this.$options.filesUrls.forEach((url) => {
        if (typeof url === "string" && url.startsWith("blob:")) {
          try {
            URL.revokeObjectURL(url);
          } catch (error) {
            console.log("Error while releasing URL:", error);
          }
        }
      });
      this.$options.filesUrls = [];
      this.file = "";
      this.files = [];
      this.previewImageSrc = "";
      this.customAspectRatio = null;
      this.callback = () => null;
      this.callbackClose = () => null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
