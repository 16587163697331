export default ($t) => ({
  title: "DonationFormCustomFields",
  type: "object",
  expanded: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: true,
    },
    fields: {
      title: $t("edit_campaign.schema.donation_form_custom_fields.fields", "Fields"),
      type: "list",
      default: [],
      expanded: true,
      layout: "expanded",
      items: {
        type: "object",
        properties: {
          key: {
            title: $t("edit_campaign.schema.donation_form_custom_fields.fields.items.key", "Key"),
            type: "string",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.key_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          order: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.order",
              "Order"
            ),
            type: "number",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.order_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          available_min_amount: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.available_min_amount",
              "Available min amount"
            ),
            type: "number",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.available_min_amount_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          required: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.required",
              "Required"
            ),
            type: "boolean",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.required_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          type: {
            title: $t("edit_campaign.schema.donation_form_custom_fields.fields.items.type", "Type"),
            expanded: true,
            type: "enum",
            default: "text",
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.type_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
            items: {
              enum: [
                {
                  text: $t(
                    "edit_campaign.schema.donation_form_custom_fields.fields.items.type.items.enum.text",
                    "text"
                  ),
                  value: "text",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_form_custom_fields.fields.items.type.items.enum.ddl",
                    "ddl"
                  ),
                  value: "ddl",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_form_custom_fields.fields.items.type.items.enum.number",
                    "number"
                  ),
                  value: "number",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_form_custom_fields.fields.items.type.items.enum.multiselect",
                    "multiselect"
                  ),
                  value: "multiselect",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_form_custom_fields.fields.items.type.items.enum.ddl_search",
                    "ddl_search"
                  ),
                  value: "ddl_search",
                },
                {
                  text: $t(
                    "edit_campaign.schema.donation_form_custom_fields.fields.items.type.items.enum.ddl_checkbox",
                    "checkbox"
                  ),
                  value: "checkbox",
                },
              ],
            },
          },
          price_of_option: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.price_of_option",
              "Price of option"
            ),
            type: "number",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.price_of_option_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          price_of_option_by_total_amount: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.price_of_option_by_total_amount",
              "Price of option by total amount"
            ),
            type: "boolean",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.price_of_option_by_total_amount_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          label: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.label",
              "Label"
            ),
            type: "string",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.label_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          placeholder: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.placeholder",
              "Placeholder"
            ),
            type: "string",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.placeholder_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          custom_view: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.custom_view",
              "Custom view"
            ),
            type: "number",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.custom_view_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          gateways: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.gateways",
              "For gateways"
            ),
            type: "array",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.gateways_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          public: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.public",
              "Public"
            ),
            type: "boolean",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.public_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          checked: {
            title: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.checked",
              "Checked"
            ),
            type: "boolean",
            expanded: true,
            description: $t(
              "edit_campaign.schema.donation_form_custom_fields.fields.items.checked_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
        },
      },
    },
  },
});
