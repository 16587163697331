const onlyPositive = {
  lazy: true,
  handler: (value) => Math.abs(value),
};

export default ($t) => ({
  title: "RecurringPayments",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
      expanded: true,
    },
    installment_payments: {
      title: $t("edit_campaign.schema.recurring_payments.installment_payments", "Installment"),
      type: "boolean",
      default: false,
      expanded: true,
      showKey: true,
      description: $t("edit_campaign.schema.recurring_payments.installment_payments_description", {
        returnEmptyOnMissing: true,
      }),
    },
    installments_number: {
      $if(form) {
        return form?.installment_payments || false;
      },
      title: $t(
        "edit_campaign.schema.recurring_payments.installments_number",
        "Installments duration"
      ),
      type: "number",
      default: 0,
      expanded: true,
      showKey: true,
      formatter: onlyPositive,
      description: $t("edit_campaign.schema.recurring_payments.installments_number_description", {
        returnEmptyOnMissing: true,
      }),
    },
    min_amount: {
      title: $t("edit_campaign.schema.recurring_payments.min_amount", "Min Amount"),
      type: "number",
      default: 1,
      min: 1,
      expanded: true,
      showKey: true,
      formatter: onlyPositive,
      description: $t("edit_campaign.schema.recurring_payments.min_amount_description", {
        returnEmptyOnMissing: true,
      }),
    },
    available_duration: {
      title: $t("edit_campaign.schema.recurring_payments.available_duration", "Available Duration"),
      type: "array",
      inputType: "number",
      default: [],
      expanded: true,
      showKey: true,
      description: $t("edit_campaign.schema.recurring_payments.available_duration_description", {
        returnEmptyOnMissing: true,
      }),
    },
    duration: {
      title: $t("edit_campaign.schema.recurring_payments.duration", "Duration"),
      type: "string",
      default: "12M",
      expanded: true,
      formatter: {
        lazy: true,
        handler(value) {
          let num = parseInt(value, 10);

          if (isNaN(num) || num == 0) num = 1;

          num = String(Math.abs(num));

          return `${num}M`;
        },
      },
      showKey: true,
      description: $t("edit_campaign.schema.recurring_payments.duration_description", {
        returnEmptyOnMissing: true,
      }),
    },
    checked_by_default: {
      title: $t("edit_campaign.schema.recurring_payments.checked_by_default", "Checked By Default"),
      type: "boolean",
      default: false,
      expanded: true,
      showKey: true,
      description: $t("edit_campaign.schema.recurring_payments.checked_by_default_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
