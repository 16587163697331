<template>
  <div class="plate plate--wm">
    <div class="plate__media">
      <div v-if="logos[integration.attributes.service]" class="plate__logo">
        <img :src="logos[integration.attributes.service]" :alt="integration.attributes.service" />
      </div>
      <div class="plate__title">
        {{ integration.attributes.service }} {{ getSandboxPrefix(integration) }}
      </div>
    </div>

    <div class="plate__data">
      <div class="plate__date">{{ getDateFormat(integration.attributes.created_at) }}</div>

      <div class="plate__status" :class="{ 'text-success': isEnabled, 'text-danger': !isEnabled }">
        <b-form-checkbox
          v-model="isEnabled"
          switch
          @change="toggleIntegration"
          :disabled="isToggling"
        >
          {{
            isEnabled
              ? $t("integration.enabled", "Enabled")
              : $t("integration.disabled", "Disabled")
          }}
        </b-form-checkbox>
      </div>

      <div class="plate__action" @click.prevent="gatewayOverview">
        <IconOverview />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import moment from "moment";
import IconOverview from "./icon-overview.vue";

export default {
  components: {
    IconOverview,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isEnabled: this.integration.attributes.status === 1,
      isToggling: false,
    };
  },

  methods: {
    gatewayOverview() {
      this.$emit("view-data", this.integration);
    },

    getDateFormat: function (date) {
      return moment.unix(date).format("MMMM D, YYYY h:mm a");
    },

    getSandboxPrefix(s) {
      switch (s.attributes.service) {
        case "donorfuse":
          if (s.attributes.sandbox) {
            return "(Sandbox)";
          }
      }
      return "";
    },

    async toggleIntegration() {
      this.isToggling = true;
      try {
        await axios.patch(urls.toggleIntegration(this.orgId, this.integration.id), {
          enable: this.isEnabled,
        });
        this.$_notificationsMixin_makeToast(
          "Success",
          this.isEnabled
            ? this.$t("integration.enabled_success", "Integration enabled successfully")
            : this.$t("integration.disabled_success", "Integration disabled successfully")
        );
        this.$store.dispatch("getIntegrationsList");
      } catch (error) {
        this.$_notificationsMixin_handleCatch(error);
        this.isEnabled = !this.isEnabled; // Revert the toggle if request fails
      } finally {
        this.isToggling = false;
      }
    },
  },

  computed: {
    ...mapState({
      orgId: () => localStorage.getItem("orgId"),
      logos: function () {
        let logos = {
          salesforce:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/salesforce-logo.png",
          mailchimp:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/mailchimp-logo.png",
          overpass:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/overpass.svg",
          zapier:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/zapier_logo_1657439051.png",
          tax_gift:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/7943/taxgift_1660867465.png",
          comeunity:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/file_1662029944.png",
        };
        return logos;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.gateway-plate {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  &__media {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-bottom: initial;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    height: 20px;
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    img {
      height: 20px;
      width: auto;
    }
  }
  &__title {
    font-size: 16px;
    padding: 0 0 0 10px;
    text-transform: capitalize;
  }
  &__owner {
    padding-right: 10px;
    font-size: 16px;
    color: #20434e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-right: 20px;
      margin-bottom: initial;
    }
  }
  &__data {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media only screen and(min-width: 992px) {
      width: auto;
      justify-content: initial;
    }
  }
  &__ccy {
    margin-right: 15px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__verification {
    margin-right: 15px;
    min-width: 90px;
    font-size: 16px;
    text-transform: capitalize;
  }
  &__status {
    margin-right: 15px;
    min-width: 65px;
    font-size: 16px;
    color: gray;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    .custom-switch {
      margin-right: 8px;
    }
  }
  &__action {
    min-height: 25px;
    min-width: 25px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 22px;
      height: auto;
      fill: #cc6d4c;
    }
  }
}
</style>
