export default ($t) => ({
  title: "AddTeamFromCampaign",
  type: "object",
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    // hide_parent_team_field: {
    //   title: "Hide parent team field",
    //   type: "boolean",
    //   default: false
    // },
    use_phone_as_shortlink: {
      title: $t(
        "edit_campaign.schema.add_team_from_campaign.use_phone_as_shortlink",
        "Use phone as shortlink"
      ),
      type: "boolean",
      default: false,
      description: $t(
        "edit_campaign.schema.add_team_from_campaign.use_phone_as_shortlink_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    hide_btn: {
      title: $t(
        "edit_campaign.schema.add_team_from_campaign.hide_btn",
        "Hides the team form button"
      ),
      type: "boolean",
      default: false,
      description: $t(
        "edit_campaign.schema.add_team_from_campaign.hide_btn_description",
        "Hides the team form button when add_team_from_campaign is enabled"
      ),
    },
    // send_sms: {
    //   title: "Send sms",
    //   type: "boolean",
    //   default: true
    // },
    // parent_team_list: {
    //   title: "Parent team list",
    //   type: "enum",
    //   default: [],
    //   items: {
    //     title: "Items",
    //     type: "enum",
    //     default: "",
    //     enum: [
    //       {
    //         text: "All teams",
    //         value: ""
    //       },
    //       {
    //         text: "Parent only",
    //         value: "parent_only"
    //       },
    //       {
    //         text: "Grandparent only",
    //         value: "grandparent_only"
    //       }
    //     ]
    //   }
    // },
    // theme: {
    //   title: "Theme",
    //   type: "string",
    //   default: "",
    //   pattern: "^.*$"
    // },
    // "image": {
    //   "title": "Image",
    //   "type": "object",
    //   "properties": {
    //     "src": {
    //       "title": "Src",
    //       "type": "string",
    //       "default": "",
    //       "pattern": "^.*$"
    //     },
    //     "disable_change": {
    //       "title": "Disable change",
    //       "type": "boolean",
    //       "default": true
    //     },
    //     "hide": {
    //       "title": "Hide",
    //       "type": "boolean",
    //       "default": true
    //     }
    //   }
    // },
    form: {
      title: $t("edit_campaign.schema.add_team_from_campaign.form", "Form"),
      type: "object",
      properties: {
        leader_name: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.leader_name", "Leader name"),
          type: "object",
          properties: {
            hide: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.leader_name.hide",
                "Hide"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.leader_name.hide_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            required: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.leader_name.required",
                "Required"
              ),
              type: "boolean",
              default: true,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.leader_name.required_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
        phone: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.phone", "Phone"),
          type: "object",
          properties: {
            hide: {
              title: $t("edit_campaign.schema.add_team_from_campaign.form.phone.hide", "Hide"),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.phone.hide_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            required: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.phone.required",
                "Required"
              ),
              type: "boolean",
              default: true,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.phone.required_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
        email: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.email", "Email"),
          type: "object",
          properties: {
            hide: {
              title: $t("edit_campaign.schema.add_team_from_campaign.form.email.hide", "Hide"),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.email.hide_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            required: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.email.required",
                "Required"
              ),
              type: "boolean",
              default: true,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.email.required_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
        parent_team: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.parent_team", "Parent team"),
          type: "object",
          properties: {
            hide: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.parent_team.hide",
                "Hide"
              ),
              type: "boolean",
              default: true,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.parent_team.hide_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            required: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.parent_team.required",
                "Required"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.parent_team.required_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
        description: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.description", "Description"),
          type: "object",
          properties: {
            hide: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.description.hide",
                "Hide"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.description.hide_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            required: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.description.required",
                "Required"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.description.required_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
        goal: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.goal", "Goal"),
          type: "object",
          properties: {
            allow_edit: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.goal.allow_edit",
                "Allow Edit"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.goal.allow_edit_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            allow_arbitrary_currency: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.goal.allow_arbitrary_currency",
                "Allow arbitrary currency"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.goal.allow_arbitrary_currency_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            min: {
              title: $t("edit_campaign.schema.add_team_from_campaign.form.goal.min", "Min Goal"),
              type: "number",
              default: 0,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.goal.min_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            max: {
              title: $t("edit_campaign.schema.add_team_from_campaign.form.goal.max", "Max Goal"),
              type: "number",
              default: 100000,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.goal.max_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
        donor_goal: {
          title: $t("edit_campaign.schema.add_team_from_campaign.form.donor_goal", "Donor goal"),
          type: "object",
          properties: {
            allow_edit: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.donor_goal.allow_edit",
                "Allow Edit"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.donor_goal.allow_edit_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            hide: {
              title: $t("edit_campaign.schema.add_team_from_campaign.form.donor_goal.hide", "Hide"),
              type: "boolean",
              default: true,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.donor_goal.hide_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
            required: {
              title: $t(
                "edit_campaign.schema.add_team_from_campaign.form.donor_goal.required",
                "Required"
              ),
              type: "boolean",
              default: false,
              description: $t(
                "edit_campaign.schema.add_team_from_campaign.form.donor_goal.required_description",
                {
                  returnEmptyOnMissing: true,
                }
              ),
            },
          },
        },
      },
    },
    colors: {
      title: $t("edit_campaign.schema.add_team_from_campaign.colors", "Form colors"),
      type: "object",
      properties: {
        value: {
          title: $t("edit_campaign.schema.add_team_from_campaign.colors.value", "Active"),
          type: "boolean",
          default: false,
          description: $t("edit_campaign.schema.add_team_from_campaign.colors.value_description", {
            returnEmptyOnMissing: true,
          }),
        },
        background: {
          title: $t("edit_campaign.schema.add_team_from_campaign.colors.background", "Background"),
          type: "color",
          default: "#ffffff",
          description: $t(
            "edit_campaign.schema.add_team_from_campaign.colors.background_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
        background_secondary: {
          title: $t(
            "edit_campaign.schema.add_team_from_campaign.colors.background_secondary",
            "Background secondary"
          ),
          type: "color",
          default: "#fbfcff",
          description: $t(
            "edit_campaign.schema.add_team_from_campaign.colors.background_secondary_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
        text: {
          title: $t("edit_campaign.schema.add_team_from_campaign.colors.text", "Text"),
          type: "color",
          default: "#212529",
          description: $t("edit_campaign.schema.add_team_from_campaign.colors.text_description", {
            returnEmptyOnMissing: true,
          }),
        },
        text_secondary: {
          title: $t(
            "edit_campaign.schema.add_team_from_campaign.colors.text_secondary",
            "Text secondary"
          ),
          type: "color",
          default: "#20434e",
          description: $t(
            "edit_campaign.schema.add_team_from_campaign.colors.text_secondary_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
      },
    },
    banner: {
      title: $t("edit_campaign.schema.add_team_from_campaign.banner", "Banner"),
      type: "object",
      expanded: true,
      properties: {
        value: {
          title: $t("edit_campaign.schema.add_team_from_campaign.banner.value", "Active"),
          type: "boolean",
          default: false,
          description: $t("edit_campaign.schema.add_team_from_campaign.banner.value_description", {
            returnEmptyOnMissing: true,
          }),
        },
        options: {
          title: $t("edit_campaign.schema.add_team_from_campaign.banner.options", "Images"),
          type: "list",
          default: [],
          items: {
            type: "object",
            properties: {
              src: {
                title: $t("edit_campaign.schema.add_team_from_campaign.banner.options.src", "Src"),
                type: "image",
                default: "",
                description: $t(
                  "edit_campaign.schema.add_team_from_campaign.banner.value_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
      },
    },
    goal_categories: {
      title: $t("edit_campaign.schema.add_team_from_campaign.goal_categories", "Goal categories"),
      type: "object",
      component: "GoalCategoriesSection",
      expanded: true,
      properties: {
        value: {
          title: "Active",
          type: "boolean",
          default: false,
        },
        view: {
          title: "View Mode",
          type: "enum",
          default: "card-list",
          items: {
            enum: [
              {
                text: "Card list",
                value: "card-list",
              },
              {
                text: "Dropdown list",
                value: "standard",
              },
            ],
          },
        },
        options: {
          title: "Items",
          type: "list",
          default: [],
          items: {
            type: "object",
            properties: {
              price: {
                title: "Price",
                type: "number",
                default: 0,
              },
              title: {
                title: "Title",
                type: "string",
                default: "",
              },
              subtitle: {
                title: "Subtitle",
                type: "string",
                default: "",
              },
              image: {
                title: "Image",
                type: "string",
                default: "",
              },
              level_ids: {
                title: "Levels",
                type: "array",
                default: [],
                items: {
                  title: "Items",
                  type: "number",
                  default: 0,
                },
              },
            },
          },
        },
      },
    },
  },
});
