export default ($t) => ({
  title: "Data2Columns",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
  },
});
