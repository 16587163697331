import axios from "axios";
import route from "@/router.js";
import urls from "@/constants/urls.js";
import { routeUserAfterLogin } from "@/helpers/userRoute";

const state = {
  user: null,
  errorLogin: [],
  resetPasswordSuccess: false,
};

const mutations = {
  setUser(state, value) {
    if (!value.attributes.require_2fa_code) {
      localStorage.setItem("orgId", value.id);
      localStorage.setItem("c_t", value.attributes.token);
      localStorage.setItem("jwt_token", value.attributes.jwt_token);
      localStorage.setItem("type", value.type);
      const expDate = new Date(value.attributes.exp_date * 1000);
      const token = value.attributes.jwt_token;
      document.cookie = `jwt_token=${token}; domain=.charidy.com; path=/; expires=${expDate};`;
    }
    state.user = value;
  },

  unsetUser(state) {
    state.user = null;
  },

  setErrorLogin(state, value) {
    state.errorLogin = value;
  },

  setResetPasswordSuccess(state, value) {
    state.resetPasswordSuccess = value;
  },
};

const getters = {
  user: (state) => () => state.user,

  require2FACode: (state) => state.user?.attributes?.require_2fa_code || false,

  isLoggedIn(state) {
    return state.user !== null;
  },

  token: (state) => () => {
    if (state.user != null && state.user.attributes != null) {
      return state.user.attributes.token;
    }
    return "";
  },
};

const actions = {
  unsetUser({ commit }) {
    localStorage.removeItem("orgId");
    localStorage.removeItem("c_t");
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("type");
    localStorage.removeItem("navChoice");
    document.cookie = `jwt_token=; domain=.charidy.com; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    commit("setOrganizations", []);
    commit("setAccount", []);
    commit("setOrgId", null, { root: true });
    commit("unsetUser");
  },

  async checkToken({ commit }) {
    const token = route.currentRoute.params.token || route.currentRoute.query.token;
    let currentPath = route.currentRoute.path;

    if (currentPath.includes("donor-log-in") && route.currentRoute.meta.donorDashboard) {
      currentPath = "/donor-dashboard";
    } else if (currentPath.includes("login")) {
      currentPath = "/dashboard";
    }

    if (token) {
      try {
        const response = await axios.get(urls.login, { params: { token: token } });
        let user = response.data.data;
        commit("setUser", user);
        await route.push({ path: currentPath });
        return;
      } catch {
        localStorage.removeItem("orgId");
        localStorage.removeItem("c_t");
        localStorage.removeItem("type");
        commit("setOrganizations", []);
        commit("setAccount", []);
        commit("errorLogin", ["Wrong token"]);
        commit("setOrgId", null, { root: true });
      }
    } else if (route.currentRoute.meta.unifiedlogin) {
      const navChoice = localStorage.getItem("navChoice");
      if (navChoice) {
        await route.push({ path: navChoice });
        return;
      }
    }

    return Promise.resolve();
  },

  loginUser({ commit }, options) {
    commit("setErrorLogin", []);

    const data = options[0];
    const { noRouting = false } = options[1] || {};

    return axios
      .post(urls.login, data)
      .then((response) => {
        let user = response.data.data;
        commit("setOrgId", user.id, { root: true });
        commit("setUser", user);

        if (noRouting) {
          return { user };
        }

        return routeUserAfterLogin(user);
      })
      .catch((e) => {
        if (e.response.data) {
          if (e.response.data.error) {
            const data = e.response.data;

            commit("setErrorLogin", [data.error_translation_key, data.error]);
          } else {
            commit("setErrorLogin", [e.response.data]);
          }
        }
      });
  },

  resetPassword({ commit }, data) {
    commit("setErrorLogin", []);
    commit("setResetPasswordSuccess", false);

    axios
      .post(urls.resetPassword, data)
      .then((response) => {
        if (response.data.Result == "ok") {
          commit("setResetPasswordSuccess", true);
        } else {
          commit("setErrorLogin", ["Email not found"]);
        }
      })
      .catch((e) => {
        if (e.response) {
          const data = e.response.data;

          commit("setErrorLogin", [data.error_translation_key, data.error]);
        }
      });
  },

  clearError({ commit }) {
    commit("setErrorLogin", []);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
