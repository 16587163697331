export default ($t) => ({
  title: "TitleSliderVue",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    video_background: {
      title: $t("edit_campaign.schema_title_slider_vue_video_background", "Video background"),
      type: "boolean",
      default: false,
      description: "To set the video as a background on Classic (as we have on standard)",
    },
    video_autoplay: {
      title: $t("edit_campaign.schema_title_slider_vue_video_autoplay", "Video autoplay"),
      type: "boolean",
      default: false,
      description: "To autoplay the video set as background",
    },
    video_orientation: {
      title: $t("edit_campaign.schema_title_slider_vue_video_orientation", "Video orientation"),
      type: "enum",
      default: "horizontal",
      description: $t(
        "edit_campaign.schema_title_slider_vue_video_orientation_description",
        "Sets orientation of video modal"
      ),
      items: {
        enum: [
          {
            text: $t(
              "edit_campaign.schema_title_slider_vue_video_orientation_horizontal",
              "Horizontal"
            ),
            value: "horizontal",
          },
          {
            text: $t(
              "edit_campaign.schema_title_slider_vue_video_orientation_vertical",
              "Vertical"
            ),
            value: "vertical",
          },
        ],
      },
    },
    open_video_default_minimized: {
      title: $t(
        "edit_campaign.schema_title_slider_vue_open_video_default_minimized",
        "Open video default minimized"
      ),
      type: "boolean",
      expanded: true,
      default: false,
      description: "To open up the video automatically minimized",
    },
    autoplay_speed: {
      title: $t("edit_campaign.schema_title_slider_vue_autoplay_speed", "Autoplay speed"),
      type: "number",
      default: 3000,
      description: "",
    },
    custom_slider: {
      title: $t("edit_campaign.schema_title_slider_vue_custom_slider", "Custom slider"),
      type: "enum",
      default: "",
      description: $t("edit_campaign.schema_title_slider_vue_custom_slider_description", {
        returnEmptyOnMissing: true,
      }),
      items: {
        enum: [
          {
            text: $t("edit_campaign.schema_title_slider_vue_custom_slider.empty", "Empty"),
            value: "",
          },
          {
            text: $t(
              "edit_campaign.schema_title_slider_vue_custom_slider.flipping_booklet",
              "flipping_booklet"
            ),
            value: "flipping_booklet",
          },
          {
            text: $t(
              "edit_campaign.schema_title_slider_vue_custom_slider.donation_designations",
              "donation_designations"
            ),
            value: "donation_designations",
          },
        ],
      },
    },
    effect: {
      title: $t("edit_campaign.schema_title_slider_vue_effect", "Transition effect"),
      type: "enum",
      default: "",
      description: $t("edit_campaign.schema_title_slider_vue_effect_description", {
        returnEmptyOnMissing: true,
      }),
      items: {
        enum: [
          {
            text: $t("edit_campaign.schema_title_slider_vue_effect.empty", "Empty"),
            value: "",
          },
          {
            text: $t("edit_campaign.schema_title_slider_vue_effect.slide", "slide"),
            value: "slide",
          },
          {
            text: $t("edit_campaign.schema_title_slider_vue_effect.fade", "fades"),
            value: "fade",
          },
          {
            text: $t("edit_campaign.schema_title_slider_vue_effect.cube", "cube"),
            value: "cube",
          },
          {
            text: $t("edit_campaign.schema_title_slider_vue_effect.coverflow", "coverflow"),
            value: "coverflow",
          },
          {
            text: $t("edit_campaign.schema_title_slider_vue_effect.flip", "flip"),
            value: "flip",
          },
        ],
      },
    },
    bg: {
      $if(form) {
        return form?.custom_slider === "flipping_booklet" || false;
      },
      title: $t("edit_campaign.schema_title_slider_vue_bg", "flipping_booklet background"),
      type: "image",
      preview: true,
      description: $t("edit_campaign.schema_title_slider_vue_bg_description", {
        returnEmptyOnMissing: true,
      }),
    },
    config: {
      $if(form) {
        return form?.custom_slider === "donation_designations" || false;
      },
      title: $t(
        "edit_campaign.schema_title_slider_vue_config",
        "donation_designations buttons configuration"
      ),
      expanded: true,
      type: "object",
      properties: {
        buttons: {
          type: "list",
          default: [],
          items: {
            type: "object",
            properties: {
              stream_key: {
                title: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.stream_key",
                  "Stream key"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.stream_key_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              title: {
                title: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.title",
                  "Title"
                ),
                type: "string",
                default: "",
                description: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.title_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              amount: {
                title: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.amount",
                  "Amount"
                ),
                type: "number",
                default: 0,
                description: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.amount_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              description: {
                title: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.description",
                  "Description"
                ),
                type: "string",
                maxLength: 512,
                default: "",
                description: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.description_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              icon_url: {
                title: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.icon_url",
                  "Icon url"
                ),
                type: "image",
                description: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.icon_url_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
              background_url: {
                title: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.background_url",
                  "Background url"
                ),
                type: "image",
                description: $t(
                  "edit_campaign.schema.edit_campaign.schema_title_slider_vue_config.buttons.background_url_description",
                  {
                    returnEmptyOnMissing: true,
                  }
                ),
              },
            },
          },
        },
      },
    },
  },
});
