import { singleLineString } from "@/helpers/filters";
import _Quill from "quill";

const Quill = window.Quill || _Quill;
const BlockEmbed = Quill.import("blots/block/embed");
const Link = Quill.import("formats/link");

const ATTRIBUTES = ["height", "width"];

class CustomVideo extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    const dataSrc = this.sanitize(value?.src || value || "");
    const dataSrcType = this.sanitize(value?.srcType || "");
    node.setAttribute("data-src", dataSrc);
    node.setAttribute("data-src-type", dataSrcType);

    node.setAttribute("width", value?.width || "400");
    node.setAttribute("height", value?.height || "300");

    const element = this.chooseElementBasedOnUrl(dataSrc, "dom", {
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
    });

    if (element.classList.contains("video-wrapper")) {
      const iframe = element.querySelector("iframe");
      const handle = element.querySelector(".resize-handle");
      let isResizing = false;
      let newWidth, newHeight;

      handle.addEventListener("mousedown", (e) => {
        e.preventDefault();
        e.stopPropagation();

        isResizing = true;
        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = parseInt(iframe.style.width, 10);
        const startHeight = parseInt(iframe.style.height, 10);

        const tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.top = "0";
        tempDiv.style.left = "0";
        tempDiv.style.right = "0";
        tempDiv.style.bottom = "0";
        tempDiv.style.background = "rgba(0,0,0,0.2)";
        element.appendChild(tempDiv);

        const onMouseMove = (moveEvent) => {
          if (!isResizing) return;

          moveEvent.preventDefault();
          moveEvent.stopPropagation();

          const deltaX = moveEvent.clientX - startX;
          const deltaY = moveEvent.clientY - startY;

          newWidth = Math.max(startWidth + deltaX, 200);
          newHeight = Math.max(startHeight + deltaY, 150);

          requestAnimationFrame(() => {
            if (!isResizing) return;
            tempDiv.style.width = `${newWidth}px`;
            tempDiv.style.height = `${newHeight}px`;
          });
        };

        const onMouseUp = (upEvent) => {
          upEvent.preventDefault();
          upEvent.stopPropagation();
          isResizing = false;

          if (newWidth && newHeight) {
            iframe.style.width = `${newWidth}px`;
            iframe.style.height = `${newHeight}px`;
            node.setAttribute("width", newWidth);
            node.setAttribute("height", newHeight);
          }

          element.removeChild(tempDiv);

          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
          document.removeEventListener("mouseup", onMouseUp, true);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("mouseup", onMouseUp, true);
      });

      window.addEventListener("blur", () => {
        isResizing = false;
      });
    }

    node.appendChild(element);
    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static getSrcType(url) {
    const isUrlContainsExt = (url, ext) => {
      if (!url) {
        return false;
      }

      return url.toLowerCase().indexOf(ext) !== -1;
    };

    if (isUrlContainsExt(url, ".mp4")) {
      return "video/mp4";
    } else if (isUrlContainsExt(url, ".mov")) {
      return "video/quicktime";
    } else {
      return null;
    }
  }

  static chooseElementBasedOnUrl(url, type, dimensions = {}) {
    const srcType = this.getSrcType(url);

    if (!srcType) {
      return this.createIframeElement(url, type, dimensions);
    }

    return this.createVideoElement(url, srcType, type);
  }

  static createVideoElement(src, srcType, nodeType = "dom") {
    const style = "display:inline-block;max-width:400px;";
    if (nodeType === "dom") {
      const video = document.createElement("video");
      video.muted = true;
      video.controls = true;
      video.classList = ["c-custom-video"];
      video.style = style;

      const source = document.createElement("source");
      source.src = src;
      source.type = srcType;

      video.appendChild(source);
      return video;
    } else if (nodeType === "html") {
      return singleLineString`
        <div class="c-custom-video" data-src="${src}" data-src-type="${srcType}">
          <video
            controls
            muted
            style="${style}">
            <source>
              src="${src}"
              type="${srcType}"
            </source>
          </video>
        </div>
      `;
    }
  }

  static createIframeElement(src, type = "dom", dimensions = {}) {
    const style = "display:block;max-width:100%;margin:0 auto;";
    const sandbox = "allow-scripts allow-same-origin allow-presentation";
    if (type === "dom") {
      const wrapper = document.createElement("div");
      wrapper.classList.add("video-wrapper", "c-custom-video");
      wrapper.style.display = "inline-block";

      const iframe = document.createElement("iframe");
      iframe.style = style;
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", true);
      iframe.setAttribute("sandbox", sandbox);
      iframe.setAttribute("src", src);
      iframe.classList.add("ql-video");

      iframe.style.width = `${dimensions.width || 400}px`;
      iframe.style.height = `${dimensions.height || 300}px`;

      const handle = document.createElement("div");
      handle.className = "resize-handle se";

      wrapper.appendChild(iframe);
      wrapper.appendChild(handle);

      return wrapper;
    } else if (type === "html") {
      return singleLineString`
        <div class="video-wrapper c-custom-video" style="display: inline-block;">
          <iframe
            class="ql-video"
            frameborder="0"
            allowfullscreen
            sandbox="${sandbox}"
            src="${src}"
            style="${style}; width: ${dimensions.width || 400}px; height: ${
        dimensions.height || 300
      }px;">
          </iframe>
          <div class="resize-handle se"></div>
        </div>
      `;
    }
  }

  static value(domNode) {
    const src = domNode.dataset?.src ?? "";
    const srcType = domNode.dataset?.srcType ?? this.getSrcType(src);
    const width = domNode.getAttribute("width");
    const height = domNode.getAttribute("height");

    return {
      srcType,
      src,
      width,
      height,
    };
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  html() {
    const { video } = this.value();
    return this.chooseElementBasedOnUrl(video, "html");
  }
}

CustomVideo.blotName = "custom-video";
CustomVideo.className = "c-custom-video";
CustomVideo.tagName = "div";

export default CustomVideo;
