/**
 * @typedef {Object} MediaTagMetadata
 * @property {string} [aspectRatio] - Aspect ratio in format "width / height"
 * @property {string[]} [conditionTemplate] - Template conditions
 * @property {string[]} [conditionDevice] - Device conditions
 */

/**
 * @typedef {Object} MediaTag
 * @property {string} text - Display text for the tag
 * @property {string} value - Unique identifier for the tag
 * @property {string} [label] - Optional group label for tags with options
 * @property {MediaTag[]} [options] - Optional nested tags
 * @property {MediaTagMetadata} [metadata] - Additional tag metadata
 */

/**
 * @typedef {Object} AspectRatioInfo
 * @property {string} text - Formatted aspect ratio (e.g. "16/9")
 * @property {number} value - Calculated aspect ratio value
 */

/**
 * @typedef {Object} ImageDimensions
 * @property {number} width - Image width in pixels
 * @property {number} height - Image height in pixels
 */

/**
 * Calculates image dimensions based on aspect ratio using Full HD as reference
 * @param {number} aspectRatio - Width divided by height
 * @param {number} [referenceWidth=1920] - Reference width in pixels
 * @returns {ImageDimensions} Calculated width and height
 */
export const getImageDimensions = (aspectRatio, referenceWidth = 1920) => {
  const width = referenceWidth;
  const height = Math.round(width / aspectRatio);
  return { width, height };
};

/**
 * Flattens nested media tags structure into a single-level array
 * @returns {MediaTag[]} Array of flattened media tags without nested options
 */
export const flattenMediaTags = () => {
  const allTags = mediaTags();
  return allTags.flatMap((tag) => tag.options || [tag]);
};

/**
 * Extracts aspect ratio information from media tags
 * @returns {Object<string, AspectRatioInfo>} Object mapping tag values to their aspect ratio information
 */
export const mediaTagAspectRatio = () => {
  const allTags = flattenMediaTags();
  const aspectRatios = {};

  for (const tag of allTags) {
    if (tag.metadata?.aspectRatio) {
      const [numerator, denominator] = tag.metadata.aspectRatio.split("/").map((n) => n.trim());
      aspectRatios[tag.value] = {
        text: `${numerator}/${denominator}`,
        value: Number(numerator) / Number(denominator),
      };
    }
  }

  return aspectRatios;
};

/**
 * Filters media tags by campaign template
 * @param {string} template - Campaign template name
 * @returns {MediaTag[]} Array of media tags filtered by template condition
 */
export const filterMediaTagsByTemplate = (template) => {
  const allTags = flattenMediaTags();
  const filtered = allTags.filter((tag) => {
    const conditions = tag.metadata?.conditionTemplate;
    return conditions.includes(template);
  });

  // If no tags match the template, return all tags that don't have a conditionTemplate
  if (filtered.length === 0) {
    return allTags.filter((tag) => tag.metadata?.conditionTemplate?.length === 0);
  }

  return filtered;
};

/**
 * Returns the complete media tags configuration
 * @returns {MediaTag[]} Array of media tags, potentially with nested options
 */
export const mediaTags = () => {
  return [
    {
      text: "Countdown page image (desktop)",
      value: "countdown_hero",
      metadata: {
        aspectRatio: "64 / 15",
        conditionTemplate: [],
        conditionDevice: ["desktop"],
      },
    },
    {
      text: "Countdown page image (mobile)",
      value: "countdown_hero_mobile",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: [],
        conditionDevice: ["mobile"],
      },
    },
    {
      text: "Countdown Video",
      value: "countdown_video",
      metadata: {
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Campaign image in the live list",
      value: "homepage",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Regular campaign slider",
      value: "slider",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Regular campaign video",
      value: "video",
      metadata: {
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Campaign page slider (desktop)",
      value: "campaign_hero",
      metadata: {
        aspectRatio: "64 / 15",
        conditionTemplate: [],
        conditionDevice: ["desktop"],
      },
    },
    {
      text: "Campaign page slider (mobile)",
      value: "campaign_hero_mobile",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: [],
        conditionDevice: ["mobile"],
      },
    },
    {
      text: "Campaign page share image (fb, whatsapp)",
      value: "shared_image",
      metadata: {
        aspectRatio: "1.91 / 1",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Projector Mode Banner",
      value: "projector_banner",
      metadata: {
        aspectRatio: "5 / 2",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Team page slider",
      value: "team_default",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Team page default avatar",
      value: "team_default_avatar",
      metadata: {
        aspectRatio: "1 / 1",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Missionsiyum Left (desktop)",
      value: "missionsiyum_left",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: ["sefer-torah-v2"],
        conditionDevice: ["desktop"],
      },
    },
    {
      text: "Missionsiyum Left (mobile)",
      value: "missionsiyum_left_mobile",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: ["sefer-torah-v2"],
        conditionDevice: ["mobile"],
      },
    },
    {
      text: "Missionsiyum Right (desktop)",
      value: "missionsiyum_right",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: ["sefer-torah-v2"],
        conditionDevice: ["desktop"],
      },
    },
    {
      text: "Missionsiyum Right (mobile)",
      value: "missionsiyum_right_mobile",
      metadata: {
        aspectRatio: "16 / 9",
        conditionTemplate: ["sefer-torah-v2"],
        conditionDevice: ["mobile"],
      },
    },
    {
      text: "Brand slider",
      value: "brand_slider",
      metadata: {
        aspectRatio: "1 / 1",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Campaign footer image (desktop)",
      value: "footer_image",
      metadata: {
        aspectRatio: "9 / 2",
        conditionTemplate: [],
        conditionDevice: [],
      },
    },
    {
      text: "Campaign footer image (mobile)",
      value: "footer_image_mobile",
      metadata: {
        aspectRatio: "13 / 8",
        conditionTemplate: [],
        conditionDevice: ["mobile"],
      },
    },
    {
      label: "Campaign Page V2 Hero",
      options: [
        {
          text: "Hero countdown (desktop)",
          value: "hero_countdown_desktop",
          metadata: {
            aspectRatio: "72 / 25",
            conditionTemplate: ["universal"],
            conditionDevice: ["desktop"],
          },
        },
        {
          text: "Hero countdown (mobile)",
          value: "hero_countdown_mobile",
          metadata: {
            aspectRatio: "213 / 250",
            conditionTemplate: ["universal"],
            conditionDevice: ["mobile"],
          },
        },
        {
          text: "Hero live (desktop)",
          value: "hero_live_desktop",
          metadata: {
            aspectRatio: "72 / 25",
            conditionTemplate: ["universal"],
            conditionDevice: ["desktop"],
          },
        },
        {
          text: "Hero live (mobile)",
          value: "hero_live_mobile",
          metadata: {
            aspectRatio: "213 / 250",
            conditionTemplate: ["universal"],
            conditionDevice: ["mobile"],
          },
        },
        {
          text: "Hero complete (desktop)",
          value: "hero_complete_desktop",
          metadata: {
            aspectRatio: "72 / 25",
            conditionTemplate: ["universal"],
            conditionDevice: ["desktop"],
          },
        },
        {
          text: "Hero complete (mobile)",
          value: "hero_complete_mobile",
          metadata: {
            aspectRatio: "213 / 250",
            conditionTemplate: ["universal"],
            conditionDevice: ["mobile"],
          },
        },
      ],
    },
    {
      label: "Campaign Page V2 Slider",
      options: [
        {
          text: "Slider countdown (desktop)",
          value: "slider_countdown_desktop",
          metadata: {
            aspectRatio: "472 / 266",
            conditionTemplate: ["universal"],
            conditionDevice: ["desktop"],
          },
        },
        {
          text: "Slider countdown (mobile)",
          value: "slider_countdown_mobile",
          metadata: {
            aspectRatio: "472 / 266",
            conditionTemplate: ["universal"],
            conditionDevice: ["mobile"],
          },
        },
        {
          text: "Slider live (desktop)",
          value: "slider_live_desktop",
          metadata: {
            aspectRatio: "472 / 266",
            conditionTemplate: ["universal"],
            conditionDevice: ["desktop"],
          },
        },
        {
          text: "Slider live (mobile)",
          value: "slider_live_mobile",
          metadata: {
            aspectRatio: "472 / 266",
            conditionTemplate: ["universal"],
            conditionDevice: ["mobile"],
          },
        },
        {
          text: "Slider complete (desktop)",
          value: "slider_complete_desktop",
          metadata: {
            aspectRatio: "472 / 266",
            conditionTemplate: ["universal"],
            conditionDevice: ["desktop"],
          },
        },
        {
          text: "Slider complete (mobile)",
          value: "slider_complete_mobile",
          metadata: {
            aspectRatio: "472 / 266",
            conditionTemplate: ["universal"],
            conditionDevice: ["mobile"],
          },
        },
      ],
    },
    {
      label: "Campaign Page V2 Other",
      options: [
        {
          text: "Past Achievements",
          value: "past_achievements",
          metadata: {
            aspectRatio: "16 / 9",
            conditionTemplate: ["universal"],
            conditionDevice: [],
          },
        },
        {
          text: "Storyfest section",
          value: "storyfest_section",
          metadata: {
            aspectRatio: "16 / 9",
            conditionTemplate: ["universal"],
            conditionDevice: [],
          },
        },
        {
          text: "Live feed section",
          value: "live_feed_section",
          metadata: {
            aspectRatio: "16 / 9",
            conditionTemplate: ["universal"],
            conditionDevice: [],
          },
        },
      ],
    },
  ];
};
