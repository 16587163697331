import { hourToPeriod } from "@/helpers/time";
import moment from "moment-timezone";

export const reportOptions = {
  gateway_currency_source: {
    ignored: true,
    headers: [
      "bank_name",
      "charge_currency",
      "offline_donation_source",
      "threshold",
      "count_donations",
      "sum_real",
      "average_real",
      "sum_total",
      "average_total",
    ],
  },
  gateway_currency_rec_duration_installments: {
    ignored: true,
    headers: [
      "bank_name",
      "charge_currency",
      "installments",
      "count_donations",
      "sum_real",
      "average_real",
      "sum_total",
      "average_total",
    ],
  },
  referrer_id_team_name: {
    ignored: true,
    headers: [
      "referrer_id",
      "team_name",
      "count_donations",
      "sum_real",
      "average_real",
      "sum_total",
      "average_total",
    ],
  },
  team_name_referrer_id: {
    ignored: true,
    headers: [
      "team_name",
      "referrer_id",
      "count_donations",
      "sum_real",
      "average_real",
      "sum_total",
      "average_total",
    ],
  },
  team_name_bank_name_referrer_id: {
    ignored: true,
    headers: [
      "team_name",
      "bank_name",
      "referrer_id",
      "count_donations",
      "sum_real",
      "average_real",
      "sum_total",
      "average_total",
    ],
  },
  pending_failed_donation_followups: {
    downloadBtnNextToSendToEmail: true,
    label: "Follow up report",
    headers: [
      "donation_id",
      "billing_name",
      "display_name",
      "gateway",
      "amount_charged",
      "amount_effective",
      "email",
      "phone",
      "team",
      "parent_team",
      "grandparent_team",
      "date",
      "status",
    ],
    headersTkFallbacks: [
      "Donatoin ID",
      "Donor Billing Name",
      "Display Name",
      "Gateway",
      "Amount charged",
      "Effective Amount",
      "Email",
      "Phone",
      "Team",
      "Parent team",
      "Grandparent team",
      "Donation date",
      "Status",
    ],
  },
  recurring_estimates_projection: {
    label: "Recurring estimates projection",
    headers: [
      "display_month",
      "total_charged",
      "recurring_donors",
      "gateway_to_total_charged",
      "gateway_to_recurring_donors",
    ],
    headersTkFallbacks: [
      "Display month",
      "Total charged",
      "Recurring donors",
      "Gateway To Total Charged",
      "Gateway To Recurring donors",
    ],
    hidePagination: true,
    hideDateFillter: false,
    classes: {
      gateway_to_total_charged: "wp-pre-line",
      gateway_to_recurring_donors: "wp-pre-line",
    },
    formatters: {
      total_charged(value, key, item) {
        const intlValue = new Intl.NumberFormat("en-US").format(value);
        return `${intlValue} ${item.attributes.campaign_currency}`;
      },
      gateway_to_total_charged(value, key, item) {
        return Object.entries(value || {})
          .map(([k, v]) => {
            const intlValue = new Intl.NumberFormat("en-US").format(v);
            return `${k}: ${intlValue} ${item.attributes.campaign_currency}`;
          })
          .join("\n");
      },
      gateway_to_recurring_donors(value) {
        return Object.entries(value || {})
          .map(([k, v]) => {
            return `${k}: ${v}`;
          })
          .join("\n");
      },
    },
  },
  pickup_report: {
    label: "Pickup report",
    headers: [
      "ID",
      "CreatedAt",
      "UpdatedAt",
      "DeletedAt",
      "campaign_id",
      "name",
      "number",
      "address",
      "telephone",
      "amount",
      "team_page",
      "comment",
      "driver",
      "envelope",
      "dispatched",
      "arrived",
      "offline",
      "cash_vouchers",
      "admin",
    ],
    headersTkFallbacks: [
      "Id",
      "Created At",
      "Updated At",
      "Deleted At",
      "Campaign Id",
      "Name",
      "Number",
      "Address",
      "Telephone",
      "Amount",
      "Team Page",
      "Comment",
      "Driver",
      "Envelope",
      "Dispatched",
      "Arrived",
      "Offline",
      "Cash Vouchers",
      "Admin",
    ],
    hidePagination: true,
    hideDateFillter: true,
    sendToEmailBtnOnly: false,
    fetchFunction: ({ store, cId, date, page, perPage }) => {
      const params = {
        date_from: date.from,
        date_to: date.to,
        page,
        per_page: perPage,
      };

      store.dispatch("getReportsPickupReport", { cId, params });
    },
  },
  report_sms: {
    label: "SMS report",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: true,
    downloadBtnOnly: true,
  },
  report_email: {
    label: "Email report",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: true,
    downloadBtnOnly: true,
  },
  utm: {
    label: "UTM report",
    headers: [
      "utm_shortlink",
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "count",
      "amount",
      "amout_real",
      "average",
      "average_real",
    ],
    headersTkFallbacks: [
      "UTMShortLink",
      "UTMSource",
      "UTMMedium",
      "UTMCampaign",
      "UTMTerm",
      "UTMContent",
      "Count",
      "Amount",
      "AmoutReal",
      "Average",
      "AverageReal",
    ],
    hidePagination: true,
    hideDateFillter: false,
    sendToEmailBtnOnly: false,
  },
  donation_failed: {
    label: "Donation failed report",
    headers: [
      { key: "id", sortable: true },
      "created_at",
      "campaign_id",
      "name",
      "display_name",
      "amount",
      "email",
      "phone",
      "device_id",
      "reason",
      "error",
    ],
    getSortBy: (sortBy, sortDesc) => {
      let map = {
        id: "id",
      };
      return `${map[sortBy] || sortBy}_${sortDesc ? "desc" : "asc"}`;
    },
    defaultSortBy: "id_desc",
    headerKey: (h) => h?.key || h,
    headersTkFallbacks: [
      "ID",
      "CreatedAt",
      "Campaign ID",
      "Name",
      "Display name",
      "Amount",
      "Email",
      "Phone",
      "Device ID",
      "Reason",
      "Error",
    ],
    hideDateFillter: false,
    hideSendToEmail: true,
    downloadBtnNextToSendToEmail: true,
    fetchFunction: ({ store, cId, page, perPage, sortBy, excludeZereoAmounts, date }) => {
      const params = {
        page,
        per_page: perPage,
        sort_by: sortBy,
        exclude_zereo_amounts: Number(excludeZereoAmounts),
        date_from: date.from,
        date_to: date.to,
      };

      store.dispatch("getReportsDonationFailed", { cId, params });
    },
    formatters: {
      name(value, key, item) {
        return `${item.billing_first_name} ${item.billing_last_name}`;
      },
      created_at(date) {
        return moment(date).format("MMMM D, YYYY h:mm a");
      },
      amount(value, key, item) {
        return `${item.amount} ${item.currency}`;
      },
    },
  },
  foundation_report: {
    label: "Foundation report",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: true,
    downloadBtnOnly: true,
  },
  past_campaign_donors_outreach: {
    label: "Past campaigns donors outreach",
    headers: [
      "$selected",
      "id",
      "billing_name",
      "display_name",
      "charge_amount",
      "effective_amount",
      "email",
      "phone",
      "teams",
      "date",
      "actions",
    ],
    headerKey: (h) => h?.key || h,
    getSortBy: (sortBy, sortDesc) => {
      let map = {
        billing_name: "fname",
        charge_amount: "real_payment",
        effective_amount: "total",
        date: "date_added",
      };
      return `${map[sortBy] || sortBy}_${sortDesc ? "desc" : "asc"}`;
    },
    headersTkFallbacks: [
      "Selected",
      "Donation ID",
      "Donor Billing Name",
      "Display Name",
      "Amount charged",
      "Effective Amount",
      "Email",
      "Phone",
      "Teams",
      "Donation date",
      "Actions",
    ],
    classes: {
      teams: "wp-pre-line",
    },
    formatters: {
      charge_amount(value, key, item) {
        return `${value} ${item.currency_code}`;
      },
      effective_amount(value, key, item) {
        return `${value} ${item.currency_code}`;
      },
      date(v) {
        const d = new Date(v);
        return d.toLocaleString("en-US");
      },
      teams(value, key, item) {
        return (item.team_list || [])
          .map((t) => {
            return `${t.id}: ${t.name}`;
          })
          .join("\n");
      },
    },
    hidePagination: false,
    hideDateFillter: true,
    hideSendToEmail: true,
    downloadBtnNextToSendToEmail: true,
    rowSelection: true,
  },
  stripe_recurring_plan_summary: {
    label: "Stripe Recurring Plan Summary",
    headers: [
      {
        key: "campaign_id",
        sortable: false,
      },
      {
        key: "recurring",
        sortable: false,
      },
      {
        key: "donor_name",
        sortable: false,
      },
      {
        key: "email",
        sortable: false,
      },
      {
        key: "stripe_account",
        sortable: false,
      },
      {
        key: "org_legal_name",
        sortable: false,
      },
      {
        key: "duration",
        sortable: true,
      },
      {
        key: "charges",
        sortable: false,
      },
      {
        key: "charge",
        sortable: false,
      },
      {
        key: "currency",
        sortable: false,
      },
      {
        key: "total_charged",
        sortable: false,
      },
      {
        key: "remaining",
        sortable: false,
      },
      {
        key: "estimated_total",
        sortable: false,
      },
      {
        key: "in_campaign_currency",
        sortable: false,
      },
      {
        key: "campaign_currency",
        sortable: false,
      },
      {
        key: "plan_status",
        sortable: false,
      },
      {
        key: "last_charge",
        sortable: false,
      },
      {
        key: "bank_name",
        sortable: false,
      },
      {
        key: "reason_canceled",
        sortable: false,
      },
    ],
    headerKey: (h) => h?.key || h,
    getSortBy: (sortBy, sortDesc) => {
      let map = {
        billing_name: "fname",
        charge_amount: "real_payment",
        effective_amount: "total",
        date: "date_added",
      };
      return `${map[sortBy] || sortBy}_${sortDesc ? "desc" : "asc"}`;
    },
    headersTkFallbacks: [
      "CampaignId",
      "Recurring",
      "DonorName",
      "Email",
      "StripeAccount",
      "OrgLegalName",
      "Duration",
      "Charges",
      "Charge",
      "Currency",
      "TotalCharged",
      "Remaining",
      "EstimatedTotal",
      "InCampaignCurrency",
      "CampaignCurrency",
      "PlanStatus",
      "LastCharge",
      "BankName",
      "ReasonCanceled",
    ],
    hidePagination: false,
    hideDateFillter: true,
    hideSendToEmail: true,
    downloadBtnNextToSendToEmail: true,
  },
  stripe_report: {
    label: "Stripe report",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: false,
    hideSendToEmail: true,
    sendToEmailBtnOnly: false,
    downloadBtnOnly: false,
    downloadBtnNextToSendToEmail: false,
    showOnlyOneBtn: false,
    refreshButtonTKey: ["reports.generate_report_btn", "Generate report"],
    fetchFunction: ({ store, cId, setManualDisable, date, toEmails, acctList }) => {
      setManualDisable(true);
      const data = {
        from: date.from ? moment(date.from).format("YYYY-MM-DD") : null,
        to: date.to ? moment(date.to).format("YYYY-MM-DD") : null,
        to_emails: toEmails,
        acct_list: acctList,
      };
      store.dispatch("getReportsStripe", { cId, params: {}, data }).catch((error) => {
        store.commit("setErrors", error?.response?.data?.errors || ["Failed to fetch"]);
        setManualDisable(false);
      });
    },
  },
  not_donated: {
    label: "Organization preuploaded donors",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: true,
    hideSendToEmail: true,
    sendToEmailBtnOnly: false,
    downloadBtnOnly: true,
  },
  team_management: {
    label: "Team Management",
    headers: [
      "parent_team_name",
      "team_id",
      "team_name",
      "goal",
      "raised",
      "balance",
      "percentage_raised",
      "donors",
      "email",
      "phone",
    ],
    headersTkFallbacks: [
      "Parent Team Name",
      "Team ID",
      "Team Name",
      "Goal",
      "Raised",
      "Balance",
      "Percentage Raised",
      "Donors",
      "Email",
      "Phone",
    ],
    hideDateFillter: true,
    hideSendToEmail: true,
    downloadBtnNextToSendToEmail: true,
  },
  organization_payments_for_period: {
    label: "Report Aggregated Payments For Period",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: true,
    hideCampaignFilter: true,
    sendToEmailBtnOnly: true,
  },
  campaign_by_hour_compare_report: {
    label: "Campaign By Hour Compare Report",
    headers: [
      "time",
      "online_donations_campaign1",
      "offline_donations_campaign1",
      "online_donors_campaign1",
      "offline_donors_campaign1",
      "online_donations_campaign2",
      "offline_donations_campaign2",
      "online_donors_campaign2",
      "offline_donors_campaign2",
    ],
    headersTkFallbacks: [
      "Period",
      "Online donations campaign1",
      "Offline donations campaign1",
      "Online donors campaign1",
      "Offline donors campaign1",
      "Online donations campaign2",
      "Offline donations campaign2",
      "Online donors campaign2",
      "Offline donors campaign2",
    ],
    formatters: {
      time(value) {
        return hourToPeriod(value);
      },
    },
    hidePagination: true,
    hideDateFillter: true,
    sendToEmailBtnOnly: false,
    hideSendToEmail: true,
    enableChart: true,
  },
  two_campaigns_donation_amount: {
    label: "Campaign Donation Amount Range Stats",
    headers: [
      "donation_range",
      "total_amount_campaign1",
      "total_amount_campaign2",
      "countof_donors_campaign1",
      "countof_donors_campaign2",
      "average_amount_campaign1",
      "average_amount_campaign2",
    ],
    headersTkFallbacks: [
      "Donation Range",
      "Total Amount Campaign 1",
      "Total Amount Campaign 2",
      "Count of Donors Campaign 1",
      "Count of Donors Campaign 2",
      "Average Amount Campaign 1",
      "Average Amount Campaign 2",
    ],
    hidePagination: true,
    hideDateFillter: true,
    sendToEmailBtnOnly: false,
    hideSendToEmail: true,
  },
  integration_logs: {
    label: "Integration export list",
    headers: [
      "id",
      "source_entity_id",
      "service",
      "destination_entity_type",
      "destination_entity_id",
      "successful",
    ],
    headerKey: (h) => h?.key || h,
    headersTkFallbacks: [
      "ID",
      "Donation ID",
      "Service",
      "Destination entity type",
      "Destination entity id",
      "Successful",
    ],
    hideSendToEmail: true,
  },
  /* post_campaign_report: {
    label: "Post Campaign Report",
    headers: [],
    headersTkFallbacks: [],
    hidePagination: true,
    hideDateFillter: true,
    hideSendToEmail: true,
    downloadBtnOnly: false,
    downloadBtnNextToSendToEmail: false,
    showOnlyOneBtn: false,
    refreshButtonTKey: ["reports.generate_report_btn", "Generate report"],
    fetchFunction: async ({ cId, setReloadData, setManualDisable, store }) => {
      setManualDisable(true);
      setReloadData(null);

      const authCode = await store
        .dispatch("getLookerStudioToken", {
          reportName: "post_campaign_report",
          params: { campaign_id: cId },
        })
        .catch((error) => {
          store.commit("setErrors", error?.response?.data?.errors || ["Failed to fetch"]);
          setManualDisable(false);
        });
      if (!authCode) {
        return;
      }

      const lookerUrl = new URL(
        `https://lookerstudio.google.com/embed/reporting/:lookerReportId/page/:lookerReportPageId`
      );
      const lookerReportId = "728a1c5f-a399-4978-8248-1de1dffa5d87";
      const lookerReportPageId = "C4eZD";

      const params = {
        "ds0.api_host": dashboardApiHost,
        "ds0.auth_code": authCode,
      };

      const searchParams = new URLSearchParams({
        params: JSON.stringify(params),
      });

      lookerUrl.search = searchParams.toString();
      let lookerStringUrl = lookerUrl.toString();
      lookerStringUrl = lookerStringUrl
        .replace(":lookerReportId", lookerReportId)
        .replace(":lookerReportPageId", lookerReportPageId);

      setManualDisable(false);
      return setReloadData(lookerStringUrl);
    },
  }, */
};

export const reportNames = Object.keys(reportOptions);
