export default ($t) => ({
  title: "MinDonationAmount",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    amount: {
      title: $t("edit_campaign.schema.min_donation_amount.amount", "Amount"),
      type: "number",
      expanded: true,
      description: $t("edit_campaign.schema.min_donation_amount.amount_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
