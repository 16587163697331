<template>
  <div class="wizard">
    <div class="main">
      <div class="taxgift-intro">
        <p>
          <b>{{ $t("integration.twilio_title", "Twilio") }}</b>
        </p>
      </div>
      <b-form-group
        id="reports-fieldset-select-entity"
        label="Phone Country Code:"
        label-for="reports-input-select-entity"
      >
        <b-form-select
          id="reports-input-select-entity"
          v-model="form.country_code"
          required
          :options="countryCodeOptions"
        ></b-form-select>
      </b-form-group>
    </div>
    <div class="footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="closeModal">
        {{ $t("integration.close") }}
      </button>
      <button class="bttn bttn--lg bttn--orange el-right" @click.prevent="addIntegration">
        {{ $t("integration.add_taxgift", "Add Integration") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        country_code: "US",
      },
      countryCodeOptions: [
        {
          value: "US",
          text: "United States",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      orgId: () => {
        return localStorage.getItem("orgId");
      },
    }),
  },

  methods: {
    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
    addIntegration() {
      const payload = this.form;
      // Please fill in API Call here
      axios
        .post(urls.addIntegration(this.orgId, "twilio"), payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          this.$emit("close-modal");
          this.$store.dispatch("getIntegrationsList");
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.el-right {
  margin-left: auto;
}
</style>
