var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.firstLoading)?_c('div',{staticClass:"w-25"},[(_vm.emailType === 'confirmation_email')?_c('div',{staticClass:"d-flex flex-column"},[(_vm.metaDataServer['do_not_send_donation_confirmation_email'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
          'edit_campaign.email_template_toggle_do_not_send_donation_confirmation_email_v2',
          'Send donation confirmation email'
        ),"plate":"","disabled":_vm.loading,"loading":_vm.loading,"true-value":false,"false-value":true},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('do_not_send_donation_confirmation_email')}},model:{value:(_vm.metaData.do_not_send_donation_confirmation_email.value),callback:function ($$v) {_vm.$set(_vm.metaData.do_not_send_donation_confirmation_email, "value", $$v)},expression:"metaData.do_not_send_donation_confirmation_email.value"}}):_vm._e()],1):(_vm.emailType === 'receipt_email')?_c('div',{staticClass:"d-flex flex-column"},[(_vm.metaDataServer['attach_pdf_to_receipt_email'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
          'edit_campaign.email_template_toggle_include_receipt_in_the_email',
          'Include receipt in the email'
        ),"plate":"","disabled":_vm.loading,"loading":_vm.loading},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('attach_pdf_to_receipt_email')}},model:{value:(_vm.metaData.attach_pdf_to_receipt_email.value),callback:function ($$v) {_vm.$set(_vm.metaData.attach_pdf_to_receipt_email, "value", $$v)},expression:"metaData.attach_pdf_to_receipt_email.value"}}):_vm._e(),(_vm.metaDataServer['do_not_send_email_receipt'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
          'edit_campaign.email_template_toggle_do_not_send_email_receipt_v2',
          'Send donation receipt email'
        ),"plate":"","disabled":_vm.loading,"loading":_vm.loading,"true-value":false,"false-value":true},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('do_not_send_email_receipt')}},model:{value:(_vm.metaData.do_not_send_email_receipt.value),callback:function ($$v) {_vm.$set(_vm.metaData.do_not_send_email_receipt, "value", $$v)},expression:"metaData.do_not_send_email_receipt.value"}}):_vm._e()],1):(_vm.emailType === 'team_confirmation_email')?_c('div',{staticClass:"d-flex flex-column"},[(_vm.metaDataServer['do_not_send_new_linked_team_account_email'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
          'edit_campaign.email_template_toggle_do_not_send_new_linked_team_account_email_v2',
          'Send new linked team account email'
        ),"plate":"","disabled":_vm.loading,"loading":_vm.loading,"true-value":false,"false-value":true},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('do_not_send_new_linked_team_account_email')}},model:{value:(_vm.metaData.do_not_send_new_linked_team_account_email.value),callback:function ($$v) {_vm.$set(_vm.metaData.do_not_send_new_linked_team_account_email, "value", $$v)},expression:"metaData.do_not_send_new_linked_team_account_email.value"}}):_vm._e(),(_vm.metaDataServer['do_not_send_team_creation_email'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t(
          'edit_campaign.email_template_toggle_do_not_send_team_creation_email_v2',
          'Send team creation email'
        ),"plate":"","disabled":_vm.loading,"loading":_vm.loading,"true-value":false,"false-value":true},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('do_not_send_team_creation_email')}},model:{value:(_vm.metaData.do_not_send_team_creation_email.value),callback:function ($$v) {_vm.$set(_vm.metaData.do_not_send_team_creation_email, "value", $$v)},expression:"metaData.do_not_send_team_creation_email.value"}}):_vm._e(),(_vm.metaDataServer['do_not_send_team_creation_email'])?_c('AppSwitchToggle',{staticClass:"my-1",attrs:{"label":_vm.$t('edit_campaign.email_template_toggle_allow_from_public_page', 'Allow from public page'),"plate":"","disabled":_vm.loading || !_vm.metaData.do_not_send_team_creation_email.value,"loading":_vm.loading},on:{"change":function($event){return _vm.onChangeAppSwitchToggle('do_not_send_team_creation_email')}},model:{value:(_vm.metaData.do_not_send_team_creation_email.allow_from_public_page),callback:function ($$v) {_vm.$set(_vm.metaData.do_not_send_team_creation_email, "allow_from_public_page", $$v)},expression:"metaData.do_not_send_team_creation_email.allow_from_public_page"}}):_vm._e()],1):_vm._e()]):_c('div',{staticClass:"d-flex flex-column"},[_c('b-skeleton',{staticClass:"my-1",attrs:{"type":"button"}}),_c('b-skeleton',{staticClass:"my-1",attrs:{"type":"button"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }