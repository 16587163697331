export default ($t) => ({
  title: "WhatsappSupportBtn",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    phone: {
      title: $t("edit_campaign.schema.whatsapp_support_btn.phone", "Whatsapp support phone number"),
      type: "string",
      default: "",
      maxLength: 256,
      description: $t(
        "edit_campaign.schema.whatsapp_support_btn.phone_description",
        "Only numbers"
      ),
    },
  },
});
