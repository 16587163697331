<template>
  <div>
    <category-title :title="$t('integration.integration')" withActions>
      <integration-add ref="integrationModal" @create-entity="createEntity" />
    </category-title>
    <template v-if="hasAccess">
      <integrationPlate
        v-for="(item, index) in integrations"
        :key="index"
        :integration="item"
        @view-data="openModals"
      >
      </integrationPlate>
    </template>

    <div class="loading" v-if="loading && hasAccess">
      <app-spinner />
    </div>

    <div v-if="integrations.length == 0 && !loading && hasAccess" class="empty">
      {{ $t("integration.not_have_gateways") }}
    </div>

    <div v-if="!hasAccess" class="empty">
      {{ $t("integration.not_have_integration_access") }}
    </div>
  </div>
</template>

<script>
import store from "../../store/store";
import { mapState } from "vuex";
import integrationAdd from "./subcomponents/integrationAdd.vue";
import integrationPlate from "./subcomponents/integration-plate.vue";
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";

export default {
  components: {
    categoryTitle,
    appSpinner,
    integrationAdd,
    integrationPlate,
  },

  created: function () {
    store.dispatch("getIntegrationsList");
  },

  computed: mapState({
    integrations: (state) => {
      return state.integrations.integrations;
    },

    loading: (state) => {
      return state.integrations.loadingIntegrations;
    },

    hasAccess: (state) => {
      return state.account.access.CanSeeAndEditOrganizationIntegrationList;
    },
  }),

  methods: {
    openModals(integrationInfo) {
      this.$refs.integrationModal.openModalView(integrationInfo);
    },

    createEntity: function () {
      this.$refs.entitiesModal.openModal();
    },
  },
};
</script>

<style lang="scss" scoped>
button.btn.entities-add.btn-secondary {
  display: none;
}
.integration {
  padding-left: 0;
  @media only screen and (min-width: 769px) {
    padding-left: 75px;
  }
}
</style>
