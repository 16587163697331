export default ($t) => ({
  title: "DonorsGoal",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    team: {
      title: $t("edit_campaign.schema.donors_goal.team", "Active for teams"),
      type: "boolean",
      description: $t("edit_campaign.schema.donors_goal.team_description", {
        returnEmptyOnMissing: true,
      }),
    },
    campaign: {
      title: $t("edit_campaign.schema.donors_goal.campaign", "Active for campaign"),
      type: "boolean",
      description: $t("edit_campaign.schema.donors_goal.campaign_description", {
        returnEmptyOnMissing: true,
      }),
    },
    team_with_donor_goal_only: {
      title: $t(
        "edit_campaign.schema.donors_goal.team_with_donor_goal_only",
        "Hide teams donors goal with 0"
      ),
      type: "boolean",
      description: $t("edit_campaign.schema.donors_goal.team_with_donor_goal_only_description", {
        returnEmptyOnMissing: true,
      }),
    },
    show_total: {
      title: $t("edit_campaign.schema.donors_goal.show_total", "Show campaign money progress"),
      type: "boolean",
      description: $t("edit_campaign.schema.donors_goal.show_total_description", {
        returnEmptyOnMissing: true,
      }),
    },
    hide_team_amount_goal: {
      title: $t("edit_campaign.schema.donors_goal.hide_team_amount_goal", "Hide team amount goal"),
      type: "boolean",
      description: $t("edit_campaign.schema.donors_goal.hide_team_amount_goal_description", {
        returnEmptyOnMissing: true,
      }),
    },
    amount: {
      title: $t("edit_campaign.schema.donors_goal.amount", "Campaign donors goal"),
      type: "number",
      expanded: true,
      description: $t("edit_campaign.schema.donors_goal.amount_description", {
        returnEmptyOnMissing: true,
      }),
    },
  },
});
