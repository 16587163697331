import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  list: [],
  totalRecords: 0,
  stats: null,
  errors: null,
  loading: false,
  downloadLoading: false,
  // Export
  exportSuccess: null,
};

const mutations = {
  setReports(state, data) {
    state.list = data;
  },

  setReportStats(state, data) {
    state.stats = data;
  },

  setTotalRecords(state, data) {
    state.totalRecords = Number(data);
  },

  setErrors(state, data) {
    state.errors = data;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setDownloadLoading(state, value) {
    state.downloadLoading = value;
  },

  setExport(state, value) {
    state.exportSuccess = value;
  },
};

const actions = {
  resetReportsState({ commit }) {
    commit("setReports", []);
    commit("setTotalRecords", 0);
    commit("setLoading", false);
    commit("setError", null);
    commit("setExport", null);
  },

  getReports({ commit }, { reportName, params }) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");
    const url = urls.reportGet.replace(":orgId", orgId).replace(":reportName", reportName);

    axios
      .get(url, { params })
      .then((response) => {
        let { data, stats } = response.data;
        commit("setTotalRecords", response.headers["x-total-records"]);
        commit("setReports", data);
        commit("setReportStats", stats);
        commit("setLoading", false);
      })
      .catch((error) => {
        commit("setErrors", error.response.data.errors);
      });
  },

  getReportsPickupReport({ commit }, { cId, params }) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");
    const url = urls.reportPickup.replace(":orgId", orgId).replace(":cid", cId);

    axios
      .get(url, { params })
      .then((response) => {
        let { data } = response.data;
        commit("setTotalRecords", response.headers["x-total-records"]);
        commit("setReports", data);
        commit("setLoading", false);
      })
      .catch((error) => {
        commit("setErrors", error?.response?.data?.errors || error);
      });
  },

  getReportsDonationFailed({ commit }, { cId, params }) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");
    const url = urls.listDonationsFailed.replace(":oid", orgId).replace(":cid", cId);

    axios
      .get(url, { params })
      .then((response) => {
        let { data } = response.data;
        commit("setTotalRecords", response.headers["x-total-records"]);
        commit("setReports", data);
        commit("setLoading", false);
      })
      .catch((error) => {
        commit("setErrors", error?.response?.data?.errors || error);
      });
  },

  getReportsStripe({ commit }, { cId, params, data }) {
    commit("setLoading", true);

    const jwtToken = localStorage.getItem("jwt_token");
    const url = urls.stripeReportRunner;

    return axios
      .post(
        url,
        {
          report_type: "detailed",
          campaign_id: cId,
          ...data,
        },
        {
          params,
          headers: { jwttoken: jwtToken },
        }
      )
      .then((response) => {
        commit("setReports", response.data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getReportsTeamManagement({ commit }, { cId, params }) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");
    const url = urls.listTeamManagement.replace(":oid", orgId).replace(":cid", cId);

    axios
      .get(url, { params })
      .then((response) => {
        let { data } = response.data;
        commit("setTotalRecords", response.headers["x-total-records"]);
        commit("setReports", data);
        commit("setLoading", false);
      })
      .catch((error) => {
        commit("setErrors", error?.response?.data?.errors || error);
      });
  },

  exportReport({ commit }, { reportName, params }) {
    const orgId = localStorage.getItem("orgId");
    const url = urls.reportGenerate.replace(":orgId", orgId).replace(":reportName", reportName);

    axios
      .get(url, { params })
      .then((response) => {
        commit("setExport", response.data);
      })
      .catch((error) => {
        commit("setErrors", error.response.data.errors);
      });
  },

  generateFileReport({ commit }, { reportName, params }) {
    commit("setDownloadLoading", true);

    const orgId = localStorage.getItem("orgId");

    let url = urls.reportGenerateFile;
    let config = {};
    let fn = axios.get;
    let setFn = (method) => {
      if (method === "post") {
        fn = (url, config) => axios.post(url, {}, config);
      }

      return fn;
    };

    if (reportName === "report_sms") {
      url = urls.reportSms;
    }

    if (reportName === "report_email") {
      url = urls.reportEmail;
    }

    if (reportName === "foundation_report") {
      url = urls.foundationReport;
      config = { params: { donations_only: params.donations_only } };
    }

    if (reportName === "donation_failed") {
      url = urls.reportDonationsFailed;
      config = { params };
    }

    if (reportName === "not_donated") {
      setFn("post");
      url = urls.reportPreuploadedDonors;
      config = { params: { campaign_id: params.campaign_id } };
    }

    let responseHandler = ({ data }) => data;

    switch (reportName) {
      case "past_campaign_donors_outreach":
        responseHandler = async ({ data }) => {
          const procID = parseInt(data.proc_session_id);
          const procProgressTracker = () =>
            new Promise((resolve, reject) => {
              const interval = setInterval(() => {
                axios
                  .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", procID))
                  .then(({ data }) => {
                    if (data.data.attributes.finished) {
                      clearInterval(interval);

                      if (![null, ""].includes(data.data.attributes.meta)) {
                        resolve(JSON.parse(data.data.attributes.meta));
                      }
                    }
                  })
                  .catch((e) => {
                    reject(e);
                  });
              }, 2000);
            });

          const fileData = await procProgressTracker();

          return fileData;
        };
        url = urls.reportGenerateFile;
        config = { params };
        break;

      case "pending_failed_donation_followups":
      case "stripe_recurring_plan_summary":
      case "team_management":
        url = urls.reportGenerateFile;
        config = { params };
        break;

      default:
        break;
    }

    url = url
      .replace(":oid", orgId)
      .replace(":cid", params.campaign_id)
      .replace(":name", reportName);

    return fn(url, config)
      .then(responseHandler)
      .finally(() => {
        commit("setDownloadLoading", false);
      });
  },

  async getLookerStudioToken({ commit }, { reportName, params }) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");
    const url = urls.getLookerStudioToken
      .replace(":orgId", orgId)
      .replace(":campaignId", params.campaign_id)
      .replace(":reportName", reportName);

    const response = await axios.get(url);
    const data = response.data;

    commit("setLoading", false);

    return data?.token;
  },
};

export default {
  state,
  actions,
  mutations,
};
