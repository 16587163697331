<template>
  <div>
    <slot />
    <b-modal
      id="media-storage-cropper-modal"
      ref="modal"
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
      @ok="ok"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ $t(`dashboard.media_storage_cropper_preview_modal_title`, `Preview media`) }}
        </h2>
      </div>
      <div>
        <b-row>
          <b-col cols="12">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
              v-if="legendTranslationKey"
            >
              {{ legendTranslationKey }}
            </legend>
            <MediaStorageCropperPreview :image="srcImage" :tag="tag" />
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="bttn bttn--lg bttn--blue" @click="cancel">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="bttn bttn--lg bttn--orange" @click="ok">Ok</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { nanoid } from "nanoid";
import MediaStorageCropperPreview from "./MediaStorageCropperPreview.vue";
import { OpenMediaStoragePreview, OnOkMediaStoragePreview } from "@/constants/providers";

export default {
  props: {
    campaignTemplate: {
      type: String,
      default: "",
    },
  },
  provide() {
    return {
      [OpenMediaStoragePreview]: this.__open,
      [OnOkMediaStoragePreview]: this.__onOK,
    };
  },
  components: {
    MediaStorageCropperPreview,
  },
  data() {
    return {
      id: nanoid(),
      src: "",
      tag: "",
      file: null,
      previewImageSrc: "",
      cropperCanvas: null,
      callback: () => null,
      legendTranslationKey: null,
    };
  },
  computed: {
    fileUrl() {
      return this.file && URL.createObjectURL(this.file);
    },
    srcImage() {
      return this.fileUrl || this.src;
    },
  },
  methods: {
    ...mapActions({
      fetchCreateFileUrl: "mediaStorage/fetchCreateFileUrl",
    }),
    __open(file, legendTranslationKey) {
      this.file = file;
      this.tag = null;
      this.legendTranslationKey = legendTranslationKey;
      this.$refs.modal.show();
    },
    close() {
      this.$refs.modal.hide();
    },
    __onOK(cb) {
      this.callback = cb;
    },
    ok() {
      return this.callback();
    },
    clearData() {
      this.$emit("hide");
      this.fileUrl && URL.revokeObjectURL(this.fileUrl);
      this.file = "";
      this.previewImageSrc = "";
      this.callback = () => null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
