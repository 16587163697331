export default ($t) => ({
  title: "NotifyOrgAboutDonationsAbove",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    amount: {
      title: $t("edit_campaign.schema.notify_org_about_donations_above.amount", "Amount"),
      type: "number",
      description: $t("edit_campaign.schema.notify_org_about_donations_above.amount_description", {
        returnEmptyOnMissing: true,
      }),
    },
    amount_threshold_per_currency: {
      title: $t(
        "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency",
        "Amount Threshold Per Currency"
      ),
      type: "list",
      default: [],
      expanded: true,
      layout: "expanded",
      items: {
        type: "object",
        properties: {
          currency: {
            title: $t(
              "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency.currency",
              "Currency Code"
            ),
            type: "string",
            default: "usd",
            description: $t(
              "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency.currency_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          amount_from: {
            title: $t(
              "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency.amount_from",
              "Amount From"
            ),
            type: "number",
            default: 0,
            description: $t(
              "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency.amount_from_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
          amount_to: {
            title: $t(
              "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency.amount_to",
              "Amount To"
            ),
            type: "number",
            default: 0,
            description: $t(
              "edit_campaign.schema.notify_org_about_donations_above.amount_threshold_per_currency.amount_to_description",
              {
                returnEmptyOnMissing: true,
              }
            ),
          },
        },
      },
    },
  },
});
