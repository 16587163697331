export default ($t) => ({
  title: "SeferTorahLomdimBlock",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    default_avatar: {
      title: $t(
        "edit_campaign.schema.sefer_torah_lomdim_block.default_avatar",
        "Default team avatar"
      ),
      type: "image",
      description: $t(
        "edit_campaign.schema.sefer_torah_lomdim_block.default_avatar_description",
        "for the best quality, recommended size 360px x 360px"
      ),
    },
  },
});
