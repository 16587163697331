import route from "@/router";

export function routeUserAfterLogin(user) {
  if (!user.attributes.require_2fa_code) {
    if (route.currentRoute.query.redirect) {
      route.push({ path: route.currentRoute.query.redirect });
      route.go();
    } else if (route.history.current.meta.donorDashboard) {
      route.push({ path: "/donor-dashboard" });
      route.go();
    } else {
      route.push({ path: "/dashboard" });
      route.go();
    }
  }
}
