<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { nanoid } from "nanoid";

const props = defineProps({
  isActive: {
    type: Boolean,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  save: {
    type: Function,
    default: () => {},
  },
  baseSettingsItem: {
    type: Object,
    default: () => null,
  },
});

const vm = getCurrentInstance();
const $t = vm.proxy.$t.bind(vm.proxy);

const MODAL_ID = `campaign-setting-modal-${nanoid()}`;
const MODAL_PREVIWE_ID = `campaign-setting-modal-preview-${nanoid()}`;

const isValidImgUrl = ref(true);

const validateImgUrl = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

onMounted(async () => {
  isValidImgUrl.value = await validateImgUrl(props.baseSettingsItem.preview);
});

function show() {
  vm.proxy.$bvModal.show(MODAL_ID);
}

function showPreview() {
  vm.proxy.$bvModal.show(MODAL_PREVIWE_ID);
}

function hide() {
  vm.proxy.$bvModal.hide(MODAL_ID);
}

const onChange = (e) => {
  props.save(e);
};
</script>

<template>
  <div>
    <template>
      <div class="tw-relative tw-border tw-border-bordercolor tw-p-5 tw-rounded-lg">
        <div v-if="isActive !== null" class="tw-absolute tw-top-5 tw-right-5 tw-w-[120px] tw-z-0">
          <b-form-checkbox switch :checked="isActive" :disabled="loading" @change="onChange">
            {{ $t("edit_campaign.campaign_setting_active", "Active") }}
          </b-form-checkbox>
        </div>
        <h3 class="tw-mb-4 tw-pe-[144px] tw-text-[22px] tw-text-[#414359] tw-font-bold">
          {{ baseSettingsItem.title || "&nbsp;" }}
        </h3>
        <p>{{ baseSettingsItem.description }}</p>
        <div class="tw-flex tw-flex-wrap tw-justify-between gap-6">
          <button
            v-if="isValidImgUrl"
            type="button"
            class="tw-text-[#007bff]"
            @click="() => showPreview()"
          >
            {{ $t("edit_campaign.campaign_setting_preview_button", "Preview") }}
          </button>
          <b-button
            type="button"
            :variant="isActive ? 'primary' : 'outline-secondary'"
            class="tw-ms-auto focus:tw-border-[#007bff]"
            :class="{ 'focus:tw-border-[#6c757d]': !isActive }"
            @click="() => show()"
          >
            {{ $t("edit_campaign.campaign_setting_configuration_button", "Configuration") }}
          </b-button>
        </div>
      </div>
    </template>
    <b-modal :id="MODAL_ID" size="xl" scrollable centered hide-footer static>
      <template #modal-header="{ hide }">
        <div>
          <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-6 tw-gap-y-4">
            <h2>{{ baseSettingsItem.title }}</h2>
            <div v-if="isActive !== null" class="tw-w-[120px]">
              <b-form-checkbox switch :checked="isActive" :disabled="loading" @change="onChange">
                {{ $t("edit_campaign.campaign_setting_active", "Active") }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="modal-header__hide" @click="hide">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </template>
      <slot name="content" :hide="hide"></slot>
    </b-modal>
    <b-modal :id="MODAL_PREVIWE_ID" size="xl" scrollable centered hide-footer>
      <template #modal-header="{ hide }">
        <div>
          <h2>{{ baseSettingsItem.title }}</h2>
          <div class="modal-header__hide" @click="hide">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </template>
      <img :src="baseSettingsItem.preview" class="tw-mx-auto" />
    </b-modal>
  </div>
</template>
