export default ($t) => ({
  title: "DonationSuccessPageConfig",
  type: "object",
  expanded: true,
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: true,
    },
    banner_image: {
      title: $t("edit_campaign.schema.donation_success_page_config.banner_image", "Banner Image"),
      type: "image",
      expanded: true,
      fileName: true,
      preview: true,
      description: $t(
        "edit_campaign.schema.donation_success_page_config.banner_image_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
    text: {
      title: $t("edit_campaign.schema.donation_success_page_config.text", "Text"),
      type: "string",
      default: "",
      maxLength: 256,
      description: $t("edit_campaign.schema.donation_success_page_config.text_description", {
        returnEmptyOnMissing: true,
      }),
    },
    custom_message: {
      title: $t(
        "edit_campaign.schema.donation_success_page_config.custom_message",
        "Custom Message (HTML)"
      ),
      type: "string",
      default: "",
      maxLength: 256,
      description: $t(
        "edit_campaign.schema.donation_success_page_config.custom_message_description",
        {
          returnEmptyOnMissing: true,
        }
      ),
    },
  },
});
