import { render, staticRenderFns } from "./unified-log-in-form.vue?vue&type=template&id=2c80283f&scoped=true&"
import script from "./unified-log-in-form.vue?vue&type=script&lang=js&"
export * from "./unified-log-in-form.vue?vue&type=script&lang=js&"
import style0 from "./unified-log-in-form.vue?vue&type=style&index=0&id=2c80283f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c80283f",
  null
  
)

export default component.exports